import { Badge, Typography } from "antd";
import React from "react";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellTaskFlashcard = (props: { task: StudyPlanTask }) => (
  <li>
    <Badge
      color="#4B39AC"
      text={<Typography.Text strong>{props.task.unitAbbrev} &#8226; Flashcards</Typography.Text>}
    />
  </li>
);
