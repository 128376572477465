import { Badge, Typography } from "antd";
import React from "react";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellTaskFinalReview = (_props: { task: StudyPlanTask }) => (
  <li>
    <Badge color="#9D59DD" text={<Typography.Text strong>Final Review</Typography.Text>} />
  </li>
);
