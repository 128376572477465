import { Button, Card, Flex, Result, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router";
import { BaseLayout } from "../../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../../components/NavigationDrawer/types";
import { MESSAGES } from "../../../i18n";
import { routes } from "../../../routes";
import { StudyPlanCalendar } from "./components/StudyPlanCalendar";
import { StudyPlanSummary } from "./components/StudyPlanSummary";
import { StudyPlanViewerHeader } from "./components/StudyPlanViewerHeader";
import { StudyPlanContext, useStudyPlan } from "./hooks/useStudyPlanSummary";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlansViewer = () => {
  const navigateTo = useNavigate();
  const plan = useStudyPlan();
  return (
    <BaseLayout
      activeNavDrawerKey={NavigationDrawerOptionKey.STUDY_PLAN_VIEWER}
      breadcrumb={[
        {
          name: MESSAGES.StudyPlansTitle,
          href: routes.studyPlans.resolve()
        },
        {
          name: (
            <Typography.Text>
              Details of{" "}
              <Typography.Text strong italic>
                {_.isNil(plan.id) //
                  ? "Study Plan"
                  : `${plan.summary.name}`}
              </Typography.Text>
            </Typography.Text>
          )
        }
      ]}
    >
      <StudyPlanContext.Provider value={plan}>
        <StudyPlanViewerHeader />
        <Flex vertical gap={12}>
          {plan.error ? (
            <Card>
              <Result
                status="404"
                title={plan.error.status}
                subTitle="Sorry, something went wrong."
                extra={
                  <Button
                    type="primary"
                    onClick={() => {
                      navigateTo(routes.studyPlans.resolve());
                    }}
                  >
                    Back to study plans.
                  </Button>
                }
              />
            </Card>
          ) : (
            <>
              <StudyPlanSummary />
              <StudyPlanCalendar />
            </>
          )}
        </Flex>
      </StudyPlanContext.Provider>
    </BaseLayout>
  );
};
