import { Modal, Spin, StepProps, Steps, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { Status } from "../hooks/useStudyPlanList";
import { StudyPlanContext } from "../StudyPlansViewer/hooks/useStudyPlanSummary";

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const buildItemStatus = (s1?: Status, s2?: Status) => (_.isEqual(s1, s2) ? "process" : "wait");

const buildItems = (status?: Status): Array<StepProps & { key: Status }> => [
  {
    key: "draft",
    title: "Draft",
    disabled: true,
    status: buildItemStatus(status, "draft"),
    description: "Work in progress."
  },
  {
    key: "published",
    title: "Published",
    disabled: _.includes(["published", "archived"], status),
    status: buildItemStatus(status, "published"),
    description: "Published to the students."
  },
  {
    key: "archived",
    title: "Archived",
    disabled: _.isEqual(status, "archived"),
    status: buildItemStatus(status, "archived"),
    description: "Inactive study plan"
  }
];

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const MoveToPublishStatusMsg = () => (
  <Typography.Text>
    Are you sure you want to publish this study plan? Once published, it will be visible to all users.
  </Typography.Text>
);
const MoveToArchivedStatusMsg = () => (
  <Typography.Text>
    Are you sure you want to archive this study plan? This operation is irreversible. Once archived, the study plan will
    no longer be enforced to the students.
  </Typography.Text>
);

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const SPStatusSteps = () => (
  <StudyPlanContext.Consumer>
    {plan => {
      const status = plan.summary.status!;
      const current = _.findIndex(buildItems(), item => _.isEqual(item.key, status));
      return (
        <Spin spinning={plan.loadingStatus || plan.loading}>
          <Steps
            size="small"
            direction="vertical"
            progressDot={(_dot, { index, status }) => (
              <span
                className={_.join(
                  [
                    //
                    "position-relative",
                    "float-left",
                    "w-full",
                    "h-full",
                    "rounded-full",
                    _.isEqual(status, "wait")
                      ? "bg-gray-200"
                      : _.isEqual(index, 0) //
                        ? "bg-yellow-500"
                        : _.isEqual(index, 1)
                          ? "bg-green-500"
                          : "bg-red-500"
                  ],
                  " "
                )}
              />
            )}
            current={current}
            items={buildItems(status)}
            onChange={async e => {
              const targetItem = buildItems()[e];
              const targetItemKey = targetItem.key;

              Modal.confirm({
                title: _.isEqual("published", targetItemKey)
                  ? "Publish Study Plan"
                  : _.isEqual("archived", targetItemKey)
                    ? "Archive Study Plan"
                    : "",
                content: _.isEqual("published", targetItemKey) ? (
                  <MoveToPublishStatusMsg />
                ) : _.isEqual("archived", targetItemKey) ? (
                  <MoveToArchivedStatusMsg />
                ) : (
                  ""
                ),
                okText: "Confirm",
                onOk: () => {
                  plan.onStatusChanged(buildItems()[e].key);
                }
              });
            }}
          />
        </Spin>
      );
    }}
  </StudyPlanContext.Consumer>
);
