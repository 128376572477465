import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductConfig } from "../../../../product-config";
import { StudyPlanValue, doUpdateWorkingFilter } from "../../../../redux/actions/students-list";
import { RdxStoreState } from "../../../../redux/types/state";
import { studyPlanOptions } from "../metadata";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const StudyPlanFilter = () => {
  const dispatch = useDispatch<any>();
  const studyPlan = useSelector(
    ({
      studentsList: {
        workingFilter: {
          studyPlan //
        }
      }
    }: RdxStoreState) => studyPlan
  );

  return getProductConfig().hasStudyPlan ? (
    <Form.Item label="Study Plan">
      <Select<string>
        data-qa-label="study-plan"
        value={studyPlan}
        getPopupContainer={({ parentNode }) => {
          return parentNode as HTMLElement;
        }}
        onChange={(studyPlan: StudyPlanValue) => {
          dispatch(doUpdateWorkingFilter({ studyPlan }));
        }}
      >
        {studyPlanOptions.map(({ label, value }) => (
          <Select.Option value={value} key={value} data-qa-label={`sp-sfilter-${value}`}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  ) : null;
};
