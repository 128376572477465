import { WorkingFilterType, WorkingFilterTypeWithoutAll } from "../../../redux/actions/partners";
import * as HttpClient from "../../http-client";
import { getUrlWithEncodedParams } from "../../http-client/utils";
import { createHeaders } from "../headers";
import { Status } from "../types";
import {
  GetTenantsResponse,
  GetTenantStudentEntryTagsResponse,
  PostTenantsResponse,
  PutTenantStudentEntryTagsRequest,
  Tenant,
  TenantProduct
} from "./types";

// -------------------------------------------------------------------------------------------------
// - Api Method: Get Tenants
// -------------------------------------------------------------------------------------------------

export const getTenants = async (args?: {
  status?: "Enabled" | "Disabled";
  filter?: string;
  limit?: number;
  offset?: number;
  type?: WorkingFilterType;
}): Promise<GetTenantsResponse> => HttpClient.get("/rest/admin/tenants", createHeaders(), args);

// -------------------------------------------------------------------------------------------------
// - Api Method: Post Tenants
// -------------------------------------------------------------------------------------------------

export const postTenant = async (
  name: string,
  type: WorkingFilterTypeWithoutAll,
  products: TenantProduct[]
): Promise<PostTenantsResponse> => HttpClient.post(`/rest/admin/tenants`, createHeaders(), { name, type, products });

// -------------------------------------------------------------------------------------------------
// - Api Method: Get Tenant
// -------------------------------------------------------------------------------------------------

export const getTenant = async (id: number): Promise<Tenant> =>
  HttpClient.get(`/rest/admin/tenants/${id}`, createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Method: Edit Tenant
// -------------------------------------------------------------------------------------------------

export const editTenant = async (
  id: number,
  name: string,
  status: Status,
  products: TenantProduct[],
  type: WorkingFilterType
): Promise<void> => HttpClient.put(`/rest/admin/tenants/${id}`, createHeaders(), { name, status, products, type });

// -------------------------------------------------------------------------------------------------
// - Api Method: Get Entry Tags
// -------------------------------------------------------------------------------------------------

export const getTenantStudentEntryTags = async (id: number): Promise<GetTenantStudentEntryTagsResponse> =>
  HttpClient.get(`/rest/admin/tenants/${id}/studentEntryTags`, createHeaders());

// -------------------------------------------------------------------------------------------------
// - Api Method: Put Entry Tags
// -------------------------------------------------------------------------------------------------

export const putTenantStudentEntryTags = async (id: number, r: PutTenantStudentEntryTagsRequest): Promise<void> =>
  HttpClient.put(`/rest/admin/tenants/${id}/studentEntryTags`, createHeaders(), r);

// -------------------------------------------------------------------------------------------------
// - Api Method: Create Tenant Report
// -------------------------------------------------------------------------------------------------

export const createTenantsReport = async (r: {
  status?: "Enabled" | "Disabled";
  filter?: string;
  type?: WorkingFilterType;
}): Promise<{ taskToken: string }> =>
  HttpClient.post(getUrlWithEncodedParams(`/rest/admin/tenants/report`, r), createHeaders(), r);
