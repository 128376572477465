import { PaginationProps } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { ApplicationToastsContext } from "../../../../components/AppToasts";
import { AltID } from "../../../../constants/courses";
import { getMyTenant } from "../../../../redux/selectors/my-tenant";
import { navApi } from "../../../../services/nav-api";
import { GetStudyPlansListRSP, StudyPlansListItem } from "../../../../services/nav-api/studyPlans/model";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type SortKey = "name" | "sectionAltId" | "startDate" | "examDate" | "tags" | "created" | "status";
export type SortOrder = "ascend" | "descend";
export type onSortChange = (key: SortKey, order: SortOrder) => void;
export interface SortProps {
  key: SortKey;
  order: SortOrder;
  onChange: onSortChange;
}

export type Filter<T> = {
  value: T;
  setValue: (val: T) => void;
  defaultValue: T;
  readonly: boolean;
  setReadOnly: (val: boolean) => void;
};

export type Status = "draft" | "published" | "archived";
export type Filters = {
  nameFilter: Filter<string | null>;
  tenantFilter: Filter<number | null>;
  statusFilter: Filter<Status[]>;
  sectionFilter: Filter<AltID[] | null>;
};

export type Paging = {
  value: PaginationProps;
  onChange: (value: PaginationProps) => void;
  setFirstPage: () => void;
};

export interface StudyPlansListContextValue {
  loading: boolean;
  data: StudyPlansListItem[];
  sort: SortProps;
  filters: Filters;
  paging: Paging;
}

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

const useFilter = <T>(initVal: T): Filter<T> => {
  const [value, setValue] = React.useState<T>(initVal);
  const [readonly, setReadOnly] = React.useState<boolean>(false);
  return {
    value,
    setValue,
    defaultValue: initVal,
    readonly,
    setReadOnly
  };
};

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

const useFilters = (): Filters => {
  const nameFilter = useFilter<string | null>(null);
  const tenantFilter = useFilter<number | null>(null);
  const statusFilter = useFilter<Status[]>(["draft", "published"]);
  const sectionFilter = useFilter<AltID[] | null>(null);
  const myTenant = useSelector(getMyTenant);

  React.useEffect(() => {
    if (_.gt(myTenant?.id, 0)) {
      tenantFilter.setReadOnly(true);
      tenantFilter.setValue(myTenant?.id!);
    }
  }, [myTenant?.id]);

  return {
    nameFilter,
    tenantFilter,
    statusFilter,
    sectionFilter
  };
};

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useStudyPlansList = (): StudyPlansListContextValue => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [sortKey, setSortKey] = React.useState<SortProps["key"]>("created");
  const [sortOrder, setSortOrder] = React.useState<SortProps["order"]>("descend");
  const [data, setData] = React.useState<GetStudyPlansListRSP["studyPlans"]>([]);
  const [paging, setPaging] = React.useState<PaginationProps>({
    total: 0,
    current: 1,
    pageSize: 50,
    showLessItems: true,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ["50", "100", "250"],
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
  });

  const filters = useFilters();
  const toastsApi = React.useContext(ApplicationToastsContext);

  // -----------------------------------------------------------------------------------------------
  // - Callbacks
  // -

  const fetchStudyPlans = React.useCallback(
    async (paging: PaginationProps, sortKey: SortKey, sortOrder: SortOrder, filters: Filters) => {
      try {
        setLoading(true);
        const rsp = await navApi.studyPlans.fetchStudyPlansList({
          tenantId: filters.tenantFilter.value!,
          offset: (paging.current! - 1) * paging.pageSize!,
          limit: paging.pageSize!,
          sort: `${_.isEqual("ascend", sortOrder) ? "" : "-"}${sortKey}`,
          name: filters.nameFilter.value!,
          status: filters.statusFilter.value,
          sectionAltId: filters.sectionFilter.value!
        });

        setPaging({ ...paging, total: rsp.total });
        setData(rsp.studyPlans);
      } catch (e) {
        const error = e as {
          status: string;
          message: string;
        };

        toastsApi.error({
          message: "Error",
          description: error.message ?? "Unknown error"
        });
      } finally {
        setLoading(false);
      }
    },
    []
  );

  // -----------------------------------------------------------------------------------------------
  // - Effects
  // -
  React.useEffect(() => {
    fetchStudyPlans(paging, sortKey, sortOrder, filters);
  }, [
    paging.size,
    paging.current,
    sortKey,
    sortOrder,
    filters.nameFilter.value,
    filters.sectionFilter.value,
    filters.statusFilter.value,
    filters.tenantFilter.value
  ]);

  return {
    loading,
    data,
    filters,
    paging: {
      value: paging,
      onChange: setPaging,
      setFirstPage: () => {
        setPaging({ ...paging, current: 1 });
      }
    },
    sort: {
      key: sortKey,
      order: sortOrder,
      onChange: (key: SortKey, order: SortOrder) => {
        setSortKey(key);
        setSortOrder(order);
      }
    }
  };
};

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

export const StudyPlansContext = React.createContext<StudyPlansListContextValue>({
  loading: false,
  data: [],
  paging: {
    value: {},
    onChange: _.noop,
    setFirstPage: _.noop
  },
  sort: {
    key: "name",
    order: "ascend",
    onChange: _.noop
  },
  filters: {
    nameFilter: {
      value: null,
      setValue: _.noop,
      defaultValue: null,
      readonly: true,
      setReadOnly: _.noop
    },
    statusFilter: {
      value: [],
      setValue: _.noop,
      defaultValue: [],
      readonly: true,
      setReadOnly: _.noop
    },
    sectionFilter: {
      value: [],
      setValue: _.noop,
      defaultValue: null,
      readonly: true,
      setReadOnly: _.noop
    },
    tenantFilter: {
      value: null,
      setValue: _.noop,
      defaultValue: null,
      readonly: true,
      setReadOnly: _.noop
    }
  }
});
