import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import _ from "lodash";
import { StudyPlanCalendar } from "../../../../../services/nav-api/studyPlans/model";
import { WeekDayHours } from "../useEditorFields/model";

dayjs.extend(duration);

// -------------------------------------------------------------------------------------------------
// - Utils
// -------------------------------------------------------------------------------------------------

export class StudyCalendarUtils {
  // -------------------------------------------------------------------------------------------------
  // - Format duration
  // -------------------------------------------------------------------------------------------------

  public static formatDuration(minutes: number): string | null {
    if (_.gt(minutes, 0)) {
      const dur = dayjs.duration(minutes, "minutes");
      if (minutes < 60) {
        return `${dur.minutes()}m`;
      }

      const hours = dur.hours();
      const mins = dur.minutes();
      return mins === 0 ? `${hours}h` : `${hours}h ${mins}m`;
    }

    return null;
  }

  /**
   *  Calculate study time per week
   */
  private static calcStudyTimePerWeek(quarterHours: number, startDate: Date, examDate: Date) {
    const hours = quarterHours / 4;
    const days = startDate && examDate ? dayjs(examDate).diff(dayjs(startDate), "day") : 0;
    return Math.ceil((hours / days) * 7);
  }

  /**
   *  Get Studytime per day
   */
  public static getStudyTimePerDay(day: dayjs.Dayjs, cal: StudyPlanCalendar<dayjs.Dayjs>[]): number {
    return (
      _.chain(cal)
        .filter(e => dayjs(e.day).isSame(day, "day"))
        .reduce((a, el) => a + el.placements.reduce((a, b) => a + b.quarterHours, 0), 0)
        .value() || 0
    );
  }

  /**
   *  Get Studytime per day
   */
  public static getStudyTimePerDayFmt(day: dayjs.Dayjs, cal: StudyPlanCalendar<dayjs.Dayjs>[]): string | null {
    return this.formatDuration(this.getStudyTimePerDay(day, cal) * 15);
  }

  /**
   *  Get Studytime per week
   */
  public static getStudyTimePerWeek(startDate: Date, examDate: Date, cal: StudyPlanCalendar[]) {
    /*
    const rows =
      _.chain(cal)
        .filter(({ day }) => dayjs(day).startOf("day") >= dayjs().startOf("day"))
        .value() || [];
        */

    const quarterHours =
      _.reduce(
        cal,
        (a, c) => {
          return a + c.placements.reduce((a, b) => a + b.quarterHours, 0);
        },
        0
      ) || 0;

    return this.calcStudyTimePerWeek(quarterHours, startDate, examDate);
  }

  /**
   *  Calculate week day hours
   */
  public static calcWeekDayHours(studyTimeWeek: number): WeekDayHours {
    const hours: WeekDayHours = [0, 0, 0, 0, 0, 0, 0];

    let index = 5; // starts in saturday
    while (studyTimeWeek) {
      hours[index] += 1;
      studyTimeWeek -= 1;
      index++;
      if (index === 7) {
        index = 0;
      }
    }
    return hours;
  }

  /**
   * Count weeks between
   */
  private static countWeekBetween(startDate: dayjs.Dayjs, examDate: dayjs.Dayjs) {
    return examDate.endOf("week").add(1, "day").diff(startDate.startOf("week"), "week");
  }

  /**
   * Count weeks and current week
   */
  public static countWeeksAndCurrentWeek(startDate: dayjs.Dayjs, examDate: dayjs.Dayjs, today: dayjs.Dayjs) {
    const weeksBetween = this.countWeekBetween(startDate, examDate);
    const daysBetween = examDate.endOf("day").diff(startDate.startOf("day"), "day");

    const startOfCurrentWeek = today.startOf("week");
    const startOfFirstWeek = startDate.startOf("week");
    const currentWeek = Math.max(1, Math.min(weeksBetween, startOfCurrentWeek.diff(startOfFirstWeek, "week") + 1));
    return {
      weeksBetween,
      currentWeek,
      daysBetween
    };
  }

  /**
   * Build weekDaysInfo
   */
  public static buildWeekDaysInfo(
    sdate: dayjs.Dayjs | undefined,
    edate: dayjs.Dayjs | undefined
  ): { weeksBetween: number; daysBetween: number } | undefined {
    if (!sdate || !edate) {
      return undefined;
    }

    const { weeksBetween, daysBetween } = StudyCalendarUtils.countWeeksAndCurrentWeek(sdate, edate, dayjs());
    return {
      weeksBetween,
      daysBetween
    };
  }
}
