import { fetchAlerts as fetchAlertsR, markAsRead, markAsUnRead } from "./alerts-reader";
import { deleteAlert, expireAlert, fetchAlerts as fetchAlertsW, saveAlert } from "./alerts-writer";
import { fetchGlobalCounters } from "./global-counters";
import { getHierarchyContent } from "./hierarchy-content";
import {
  getMySelf,
  getMySelfSettings,
  getMyTagIds,
  updateMySelfNotificationsSettings,
  updateMySelfPassword,
  updateMySelfSettings
} from "./myself";
import { deletePartnerTenant, getPartners, putPartnerStatus, putPartnerTenant } from "./partners";
import { getRoles } from "./roles";
import { logout, signIn } from "./session";
import { deleteStudentFollowed, editStudent, getStudentDetails, putStudentFollowed } from "./student-details";
import {
  addDeleteTagsFromStudents,
  assignStatusToRosterStudents,
  createStudentRoster,
  examTrackerReport,
  frPerformanceSummary,
  frSimulatedExamsScoreReport,
  getStudentRosterListingOfStudents,
  homeworkMcqFirstAttemptReport,
  homeworkPerformanceReport,
  miniExamPerformanceReport,
  miniExamsAnswersReport,
  miniExamScoreReport,
  performanceDashboardReport,
  simulatedExamPerformanceReport,
  simulatedExamsAnswersReport,
  simulatedExamsScoreReport,
  studentsListReport,
  studyTimeReport,
  timesheetReport
} from "./student-roster";
import { postMannuallyAssociated } from "./students-mannually-associated";
import {
  createStudyPlan,
  editStudyPlan,
  fetchSimulatedStudyPlan,
  fetchStudyPlanById,
  fetchStudyPlansList,
  updateStudyPlansStatus
} from "./studyPlans";
import { getTaskBlog } from "./tasks";
import { fetchData } from "./tenant-dashboard";
import {
  cloneTag,
  createTenantTag,
  deleteTenantTag,
  getTenantTags,
  moveStudentsToTag,
  updateTenantTag
} from "./tenants/tags";
import {
  createTenantsReport,
  editTenant,
  getTenant,
  getTenants,
  getTenantStudentEntryTags,
  postTenant,
  putTenantStudentEntryTags
} from "./tenants/tenants";
import { getFeatureFlags } from "./unauth/feature-flags";
import { initiate, resetPassword, validateToken } from "./unauth/forgot-password";
import { postUserInvite } from "./user-invites";
import {
  createUser,
  deleteUser,
  disableUser,
  enableUser,
  generateReport,
  getUserById,
  getUsernameInfo,
  getUsers,
  putUser,
  updateUser
} from "./users";
import { taskTokenChange } from "./web-sockets";

export const navApi = {
  alertsReader: {
    fetchAlerts: fetchAlertsR,
    markAsRead,
    markAsUnRead
  },
  alertsWriter: {
    deleteAlert,
    expireAlert,
    fetchAlerts: fetchAlertsW,
    saveAlert
  },
  globalCounters: {
    fetchGlobalCounters
  },
  hierarchyContent: {
    getHierarchyContent
  },
  myself: {
    getMySelf,
    getMySelfSettings,
    getMyTagIds,
    updateMySelfNotificationsSettings,
    updateMySelfPassword,
    updateMySelfSettings
  },
  partners: {
    getPartners,
    putPartnerStatus,
    putPartnerTenant,
    deletePartnerTenant
  },
  roles: {
    getRoles
  },
  session: {
    signIn,
    logout
  },
  studentDetails: {
    getStudentDetails,
    editStudent,
    putStudentFollowed,
    deleteStudentFollowed
  },
  studentRoster: {
    createStudentRoster,
    getStudentRosterListingOfStudents,
    assignStatusToRosterStudents,
    addDeleteTagsFromStudents,
    performanceDashboardReport,
    frPerformanceSummary,
    examTrackerReport,
    studentsListReport,
    studyTimeReport,
    timesheetReport,
    simulatedExamsScoreReport,
    frSimulatedExamsScoreReport,
    simulatedExamPerformanceReport,
    homeworkMcqFirstAttemptReport,
    homeworkPerformanceReport,
    simulatedExamsAnswersReport,
    miniExamsAnswersReport,
    miniExamScoreReport,
    miniExamPerformanceReport
  },
  studentsMannualyAssociated: {
    postMannuallyAssociated
  },
  tasks: {
    getTaskBlog
  },
  tenantDashboard: {
    fetchData
  },
  tenants: {
    cloneTag,
    createTenantTag,
    getTenants,
    getTenantTags,
    moveStudentsToTag,
    deleteTenantTag,
    updateTenantTag,
    postTenant,
    editTenant,
    getTenant,
    createTenantsReport,
    putTenantStudentEntryTags,
    getTenantStudentEntryTags
  },
  unauth: {
    initiate,
    validateToken,
    resetPassword,
    getFeatureFlags
  },
  userInvites: {
    postUserInvite
  },
  users: {
    createUser,
    updateUser,
    deleteUser,
    disableUser,
    enableUser,
    generateReport,
    getUserById,
    getUsernameInfo,
    getUsers,
    putUser
  },
  webSockets: {
    taskTokenChange
  },
  studyPlans: {
    fetchStudyPlansList,
    fetchSimulatedStudyPlan,
    fetchStudyPlanById,
    createStudyPlan,
    editStudyPlan,
    updateStudyPlansStatus
  }
};
