import { FilterFilled, FilterOutlined, FilterTwoTone, ReloadOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Tooltip } from "antd";
import { ColumnProps } from "antd/es/table";
import { FilterDropdownProps } from "antd/es/table/interface";
import _ from "lodash";
import React from "react";
import { TenantsList } from "../../../../components/TenantsSelect";
import { Tenant } from "../../../../services/nav-api/tenants/types";
import { CreateColumnProps } from "./model";

// -------------------------------------------------------------------------------------------------
// - Column builder
// -------------------------------------------------------------------------------------------------

export const createTenantColumn = ({
  sort,
  paging,
  filters: { tenantFilter }
}: CreateColumnProps): ColumnProps<{}>[] =>
  !tenantFilter.readonly
    ? [
        {
          ellipsis: true,
          sorter: true,
          sortOrder: _.isEqual(sort?.key, "tenant") ? sort?.order : undefined,
          dataIndex: "tenant",
          title: "Tenant",
          render: (tenant: Pick<Tenant, "id" | "name">) => (
            <Tooltip title={tenant.name}>
              <span>{tenant.name}</span>
            </Tooltip>
          ),
          filterIcon: _.isEqual(tenantFilter.value, tenantFilter.defaultValue) ? (
            <FilterFilled />
          ) : (
            <FilterTwoTone twoToneColor="green" />
          ),
          filterDropdown: (props: FilterDropdownProps) => {
            const [tmpFilterValue, setTmpFilterValue] = React.useState<number | null>(null);

            React.useEffect(() => {
              setTmpFilterValue(tenantFilter.value);
            }, [props.visible]);

            return (
              <Flex vertical gap={12} style={{ padding: 12 }}>
                <TenantsList value={tmpFilterValue!} onChange={setTmpFilterValue} />
                <Divider style={{ padding: 0, margin: 0 }} />
                <Flex gap={6} justify="end">
                  <Button
                    size="small"
                    className="grow"
                    icon={<ReloadOutlined />}
                    onClick={() => {
                      paging.setFirstPage();
                      tenantFilter.setValue(tenantFilter.defaultValue);
                      props.close();
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    className="grow"
                    icon={<FilterOutlined />}
                    onClick={() => {
                      paging.setFirstPage();
                      tenantFilter.setValue(tmpFilterValue);
                      props.close();
                    }}
                  >
                    Apply
                  </Button>
                </Flex>
              </Flex>
            );
          }
        }
      ]
    : [];
