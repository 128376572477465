import { Alert } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { doDiscardOperationError } from "../../../../redux/actions/alerts-writer/operation";
import { getOperationError } from "../../../../redux/selectors/alerts-writer/operation";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AlertError = () => {
  const dispatch = useDispatch<any>();
  const error = useSelector(getOperationError);
  return error ? (
    <ErrorWrapper>
      <Alert
        message={error}
        type="error"
        closable
        onClose={() => {
          dispatch(doDiscardOperationError());
        }}
      />
    </ErrorWrapper>
  ) : null;
};

// -------------------------------------------------------------------------------------------------
// - Styled Components
// -------------------------------------------------------------------------------------------------

const ErrorWrapper = styled.div`
  margin-bottom: 24px;
`;
