import { createSelector } from "reselect";
import { Tenant } from "../../services/nav-api/tenants/types";
import { UserType } from "../../services/nav-api/types";
import { RdxMySelfState } from "../reducers/myself/types";
import { RdxStoreState } from "../types/state";

// -------------------------------------------------------------------------------------------------
// - Model
// -------------------------------------------------------------------------------------------------

export interface ActiveUser {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  tenant: Pick<Tenant, "id" | "name"> | undefined;
  roleId: number;
  permissions: string[];
  idp: UserType;
  userId?: number;
  unsubscribeAlerts: boolean;
}

// -------------------------------------------------------------------------------------------------
// - Selector
// -------------------------------------------------------------------------------------------------

export const getActiveUser: (state: RdxStoreState) => ActiveUser | undefined = createSelector(
  (state: RdxStoreState) => state.myself,
  (myself: RdxMySelfState) =>
    myself && myself.user
      ? {
          firstName: myself.user.firstName,
          lastName: myself.user.lastName,
          username: myself.user.username,
          email: myself.user.email,
          tenant: myself.user.tenant,
          roleId: myself.user.roleId,
          permissions: myself.user.permissions,
          idp: myself.user.idp,
          userId: myself.user.userId,
          unsubscribeAlerts: myself.user.unsubscribeAlerts
        }
      : undefined
);

export const getActiveUserSettings = createSelector(
  (state: RdxStoreState) => state.myself,
  (myself: RdxMySelfState) => myself.userSettings
);
