import qString from "qs";
import * as HttpClient from "../../http-client";
import { createHeaders } from "../headers";
import {
  CreateStudyPlanRQ,
  GetSimulatedStudyPlan,
  GetSimulatedStudyPlanRSP,
  GetStudyPlansListRQ,
  GetStudyPlansListRSP,
  StudyPlansListItem
} from "./model";

// -------------------------------------------------------------------------------------------------
// - Api Methods
// -------------------------------------------------------------------------------------------------

export const fetchStudyPlanById = async (studyPlanId: number | string): Promise<StudyPlansListItem> =>
  HttpClient.get(`/rest/admin/studyPlans/${studyPlanId}`, createHeaders());

export const fetchStudyPlansList = async (params: Partial<GetStudyPlansListRQ> = {}): Promise<GetStudyPlansListRSP> =>
  HttpClient.get("/rest/admin/studyPlans", createHeaders(), params, { skipNulls: true, arrayFormat: "comma" });

export const fetchSimulatedStudyPlan = async (params: GetSimulatedStudyPlan): Promise<GetSimulatedStudyPlanRSP> =>
  HttpClient.get("/rest/admin/studyPlans/simulation", createHeaders(), params, {
    arrayFormat: "comma",
    skipNulls: true,
    encode: true,
    allowEmptyArrays: false
  });

export const createStudyPlan = async (tenantId: number, data: CreateStudyPlanRQ): Promise<{ id: number }> =>
  HttpClient.post(`/rest/admin/studyPlans?${qString.stringify({ tenantId })}`, createHeaders(), data);

export const editStudyPlan = async (studyPlanId: number, data: CreateStudyPlanRQ): Promise<{ id: number }> =>
  HttpClient.put(`/rest/admin/studyPlans/${studyPlanId}`, createHeaders(), data);

export const updateStudyPlansStatus = async (
  studyPlanId: number,
  status: "draft" | "published" | "archived"
): Promise<{ id: number }> =>
  HttpClient.put(`/rest/admin/studyPlans/${studyPlanId}/status`, createHeaders(), { status });
