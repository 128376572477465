import { Badge, Typography } from "antd";
import React from "react";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellTaskModule = (props: { task: StudyPlanTask }) => (
  <li>
    <Badge
      color="#1E9DB5"
      text={
        <Typography.Text strong>
          {props.task?.unitAbbrev} &#8226; {props.task?.moduleAbbrev}
        </Typography.Text>
      }
    />
  </li>
);
