import { Badge, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";
import { StudyPlanContext } from "../../../hooks/useStudyPlanSummary";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellTaskMiniExam = (props: { task: StudyPlanTask }) => (
  <li>
    <StudyPlanContext.Consumer>
      {({ tasks }) => {
        const allMiniExms = _.filter(tasks, ({ type }) => _.isEqual(type, "miniexam"));
        const miniExamIdx = _.findIndex(allMiniExms, { id: props.task.id });

        return <Badge color="#9D59DD" text={<Typography.Text strong>Mini Exam {miniExamIdx + 1}</Typography.Text>} />;
      }}
    </StudyPlanContext.Consumer>
  </li>
);
