import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, Row, Tag, Tooltip, Typography } from "antd";
import { isEqual, reduce } from "lodash";
import moment from "moment";
import React from "react";
import { MESSAGES } from "../i18n";
import { getProductConfig } from "../product-config";
import { Exam, StudentExam } from "../redux/selectors/student-exams";
import { ExamStatus } from "../services/nav-api/student-details/types";
import { format, formatDistanceToNowInDaysOrBigger } from "../utils/date-utils";
const isNil = (value: any) => value === undefined || value === null;
const isEmptyArray = (value: any) => Array.isArray(value) && !value.length;

export const EMPTY_CELL_SYMBOL = "---";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const handleCellText = (value: any) =>
  isNil(value) || isEmptyArray(value) || value === "" ? (
    <Typography.Text>{EMPTY_CELL_SYMBOL}</Typography.Text>
  ) : (
    <Typography.Text>{value}</Typography.Text>
  );

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const handleCellPercentage = (value: any) =>
  isNil(value) || isEmptyArray(value) ? (
    <Typography.Text>{EMPTY_CELL_SYMBOL}</Typography.Text>
  ) : (
    <Typography.Text>{value} %</Typography.Text>
  );

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const handleDateCell = (value: any) =>
  !value ? <Typography.Text>{EMPTY_CELL_SYMBOL}</Typography.Text> : format(value, "P");

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const handleExpirationDate = (value: any, row: any) => {
  if (row.licenseUnlimited) {
    return "Unlimited";
  }
  return handleDateCell(value);
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const handleDateDistanceFromNowInDays = (date: any, withTooltip?: boolean) => {
  if (!date) {
    return <Typography.Text>{EMPTY_CELL_SYMBOL}</Typography.Text>;
  }

  const distanceNow = formatDistanceToNowInDaysOrBigger(date, { addSuffix: true });

  if (withTooltip) {
    return (
      <Tooltip title={format(date, "P")}>
        <div className="truncate w-full h-full">
          <Typography.Text>{distanceNow}</Typography.Text>
        </div>
      </Tooltip>
    );
  }

  return distanceNow;
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const handleStudyPlanCell = (_: any, rowData: any) => {
  if (rowData && rowData.hasStudyPlan && rowData.isBehindSchedule) {
    return <Typography.Text style={{ color: "red" }}>behind schedule</Typography.Text>;
  } else if (rowData && rowData.hasStudyPlan && !rowData.isBehindSchedule) {
    return <Typography.Text style={{ color: "green" }}>on track</Typography.Text>;
  } else {
    return <Typography.Text>n/a</Typography.Text>;
  }
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const handleStudentExamExpirationDateCell = (value: any, row: StudentExam) => {
  if (row.isUnlimited) {
    return MESSAGES.UnlimitedEnrollmentTableCell;
  }
  const monthDiff = moment(value).diff(moment(), "months", true);
  let monthIcon = undefined;
  if (monthDiff < 0) {
    monthIcon = (
      <Tooltip placement="right" title={MESSAGES.Expired}>
        <ExclamationCircleOutlined style={{ fontSize: "16px", color: "#f5222d" }} />
      </Tooltip>
    );
  } else if (monthDiff < 3) {
    monthIcon = (
      <Tooltip placement="right" title={MESSAGES.ExpiringSoon}>
        <ExclamationCircleOutlined style={{ fontSize: "16px", color: "#faad14" }} />
      </Tooltip>
    );
  }
  return (
    <>
      {handleDateCell(value)} {monthIcon}
    </>
  );
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const handleStudentExamExamDateCell = (_: any, row: StudentExam) => {
  if (row.exams.length === 0) {
    return EMPTY_CELL_SYMBOL;
  }
  return row.exams.map((exam, index) => <div key={index}>{handleDateCell(exam.examDate)}</div>);
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const handleStudentExamExamGradeCell = (_: any, row: StudentExam) => {
  if (row.exams.length === 0) {
    return EMPTY_CELL_SYMBOL;
  }
  return row.exams.map((exam: Exam, index: number) => (
    <div
      key={index}
      style={{
        ...(exam.examGrade
          ? {
              color: exam.examGrade! < getProductConfig().examTrackerMinExamGrade ? "#f5222d" : "#52c41a"
            }
          : {})
      }}
    >
      <Row>
        <Col span={8}>{handleCellText(exam.examGrade)}</Col>
        {isEqual(ExamStatus.EXPIRED, exam?.examStatus) ? (
          <Col span={16} style={{ textAlign: "center" }}>
            <Tag color="error">Expired</Tag>
          </Col>
        ) : null}
      </Row>
    </div>
  ));
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export function renderWithMaxWidth(maxWidth: number) {
  return function renderer(value: string) {
    return (
      <div className="overflow-hidden text-ellipsis whitespace-nowrap" style={{ maxWidth }}>
        {value}
      </div>
    );
  };
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const composeHandlers =
  (...renders: Array<(r: any) => any>) =>
  (v: any) =>
    reduce(renders, (a, cur) => cur(a), v);
