import { Menu, MenuProps } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getActiveUser } from "../../../redux/selectors/active-user";
import { hasStudyPlansFF } from "../../../redux/selectors/ffs";
import { getHasPartnersPermission } from "../../../redux/selectors/permissions/has-manage-partners";
import { getHasTagsPermission } from "../../../redux/selectors/permissions/has-manage-tags";
import { getHasTenantsPermission } from "../../../redux/selectors/permissions/has-manage-tenants";
import { getHasUsersPermission } from "../../../redux/selectors/permissions/has-manage-users";
import { getIsSystemAdmin } from "../../../redux/selectors/permissions/is-system-admin";
import { NavigationDrawerOptionKey } from "../types";
import { AppMenuContext } from "./context";
import menuItemMyAccount from "./MIAccount";
import menuItemAdmin from "./MIAdmin";
import menuItemDashboard from "./MIDashboard";
import menuItemStudents from "./MIStudents";
import menuItemStudyPlans from "./MIStudyPlans";

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

type MenuItem = Required<MenuProps>["items"][number];

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

const findNodesPaths = (items: MenuItem[], parentKey: string | null = null): Record<string, string> =>
  _.isEmpty(items)
    ? {}
    : _.reduce(
        items,
        (a, i) => ({
          ...a,
          ...(i?.key ? { [i.key as string]: parentKey } : {}),
          ...((i as any)?.children ? findNodesPaths((i as any).children, i?.key as string) : {})
        }),
        {}
      );

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const AppMenu = (props: { activeKey: NavigationDrawerOptionKey }) => {
  const [openKeys, setOpenKeys] = React.useState<React.Key[]>([]);

  const hasTenantsPermission = useSelector(getHasTenantsPermission);
  const hasPartnersPermission = useSelector(getHasPartnersPermission);
  const hasTagsPermission = useSelector(getHasTagsPermission);
  const hasUsersPermission = useSelector(getHasUsersPermission);
  const isSystemAdmin = useSelector(getIsSystemAdmin);
  const activeUser = useSelector(getActiveUser);
  const hasStudyPlans = useSelector(hasStudyPlansFF);

  const menuItems: Array<MenuItem> = [
    ...menuItemDashboard,
    ...menuItemStudents,
    ...(hasStudyPlans //
      ? menuItemStudyPlans
      : []),
    ...menuItemAdmin({
      hasTenantsPermission,
      hasPartnersPermission,
      hasUsersPermission,
      hasTagsPermission,
      isSystemAdmin
    }),
    ...menuItemMyAccount(activeUser)
  ];

  React.useEffect(() => {
    if (!_.isEmpty(props.activeKey)) {
      const hierarchy = findNodesPaths(menuItems);
      const openKey = hierarchy?.[props.activeKey];
      const openKeys = !_.isNil(openKey) ? [openKey] : [];
      setOpenKeys(openKeys);
    }
  }, [props.activeKey]);

  return (
    <AppMenuContext.Consumer>
      {({ active }) => (
        <Menu
          mode="inline"
          className="font-semibold"
          motion={{ motionName: "" }}
          selectedKeys={[active!]}
          openKeys={openKeys as string[]}
          items={menuItems}
          onOpenChange={e => {
            setOpenKeys(e);
          }}
        />
      )}
    </AppMenuContext.Consumer>
  );
};
