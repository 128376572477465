import { SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { ErrorMessage, Field, FieldProps, Formik } from "formik";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router";
import { MESSAGES } from "../i18n";
import { WorkingFilterTypeTypesWithoutAll, WorkingFilterTypeWithoutAll } from "../redux/actions/partners";
import { routes } from "../routes";
import { Tenant, TenantProduct } from "../services/nav-api/tenants/types";
import { Status } from "../services/nav-api/types";
const CheckboxGroup = Checkbox.Group;

// -------------------------------------------------------------------------------------------------
// - Props
// -------------------------------------------------------------------------------------------------

interface TenantFormProps {
  name: string;
  status: boolean;
  type: WorkingFilterTypeWithoutAll | undefined;
  products: TenantProduct[];
}

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantsForm = (props: {
  tenant?: Tenant;
  tenantId?: number;
  loading: boolean;
  onSubmit: (props: TenantFormProps) => void;
}) => {
  const navigate = useNavigate();
  const initValues = {
    name: props.tenant ? props.tenant.name : "",
    status: props.tenant ? props.tenant.status === Status.Enabled : true,
    type: props.tenant?.type,
    products: props.tenant?.products || []
  };

  return (
    <Formik<TenantFormProps> initialValues={initValues} onSubmit={values => props.onSubmit({ ...values })}>
      {formProps => (
        <Form
          layout="vertical"
          onFinish={(e: React.FormEvent<any>) => {
            formProps.handleSubmit(e!);
          }}
        >
          {/* --- --- */}
          {/* --- --- */}
          {/* --- --- */}

          <Form.Item
            label="Name*"
            name="name"
            validateStatus={formProps.submitCount && formProps.errors.name ? "error" : "success"}
          >
            <Field name="name" validate={(v: string) => (!v.trim() ? "Name must be defined" : undefined)}>
              {({ field }: FieldProps<string, TenantFormProps>) => <Input {...field} type="text" maxLength={100} />}
            </Field>
            {formProps.submitCount && formProps.errors.name ? (
              <ErrorMessage component={() => <div className="text-red" />} name="name" />
            ) : null}
          </Form.Item>

          {/* --- --- */}
          {/* --- --- */}
          {/* --- --- */}

          <Form.Item
            label="Type*"
            name="type"
            validateStatus={formProps.submitCount && formProps.errors.type ? "error" : "success"}
          >
            <Field
              name="type"
              validate={(v: string) => (!v || parseInt(v) === -1 ? "Type must be defined" : undefined)}
            >
              {({ field }: FieldProps<TenantFormProps, TenantFormProps>) => (
                <Select
                  {...field}
                  getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                  onChange={value => {
                    formProps.setFieldValue("type", value);
                    formProps.setFieldValue("partner", undefined);
                  }}
                >
                  {WorkingFilterTypeTypesWithoutAll.map(type => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              )}
            </Field>
            {formProps.submitCount && formProps.errors.type ? (
              <ErrorMessage component={() => <div className="text-red" />} name="type" />
            ) : null}
          </Form.Item>

          {/* --- --- */}
          {/* --- --- */}
          {/* --- --- */}

          <Form.Item
            label="Products*"
            name="products"
            validateStatus={formProps.submitCount && formProps.errors.products ? "error" : "success"}
          >
            <Field
              name="products"
              validate={(value: string[]) => (_.isEqual(value.length, 0) ? "Products must be defined" : undefined)}
            >
              {({ field }: FieldProps<string[]>) => (
                <CheckboxGroup
                  {...field}
                  value={field.value}
                  options={_.values(TenantProduct).map(product => ({
                    label: product.toUpperCase(),
                    value: product
                  }))}
                  onChange={checkedValue => {
                    formProps.setFieldValue("products", checkedValue);
                  }}
                />
              )}
            </Field>
            {formProps.submitCount && formProps.errors.products ? (
              <ErrorMessage component={() => <div className="text-red" />} name="products" />
            ) : null}
          </Form.Item>

          {/* --- --- */}
          {/* --- --- */}
          {/* --- --- */}

          {props.tenant ? (
            <Form.Item>
              <Field
                label="Status*"
                name="status"
                validateStatus={formProps.submitCount && formProps.errors.status ? "error" : "success"}
              >
                {({ field }: FieldProps<boolean>) => (
                  <label>
                    <Checkbox
                      checked={field.value}
                      onChange={e => {
                        formProps.setFieldValue("status", e.target.checked);
                      }}
                    />
                    &nbsp;&nbsp;{MESSAGES.Enabled}
                  </label>
                )}
              </Field>
              {formProps.submitCount && formProps.errors.status ? (
                <ErrorMessage component={() => <div className="text-red" />} name="status" />
              ) : null}
            </Form.Item>
          ) : null}
          <Row gutter={16}>
            <Col className="flex gap-x-2">
              <Button
                disabled={props.loading}
                onClick={() => {
                  navigate(
                    props.tenantId //
                      ? routes.tenant.resolve({ id: props.tenantId.toString() })
                      : routes.tenants.resolve()
                  );
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!formProps.isValid}
                icon={<SaveOutlined />}
                loading={props.loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
