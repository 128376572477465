import cloneDeep from "lodash/cloneDeep";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { StatusFilterAllValue } from "../../../components/StatusFilter";
import { DEFAULT_LIST_PAGE, DEFAULT_LIST_SIZE } from "../../../constants/lists";
import { HttpClientErrorResponse } from "../../../services/http-client/types";
import { GetTenantsResponse } from "../../../services/nav-api/tenants/types";
import { Status } from "../../../services/nav-api/types";
import { RdxTenantsSubState } from "../../reducers/tenants/tenants";
import { getIsTenantsListFilterEqualsToAppliedFilter } from "../../selectors/is-tenants-list-filter-equals-to-active";
import { getIsTenantsListLoading } from "../../selectors/is-tenants-list-loading";
import { Action, ThunkAction } from "../../types/action";
import { RdxStoreState } from "../../types/state";
import { WorkingFilterType } from "../partners";
import { RdxFetchStatus } from "./../../types/status";

export const FETCH_TENANTS_STARTED = "FETCH_TENANTS_STARTED";
export const FETCH_TENANTS_COMPLETED = "FETCH_TENANTS_COMPLETED";
export const TENANTS_LIST_SETTINGS_CHANGED = "TENANTS_LIST_SETTINGS_CHANGED";
export const WORKING_TENANTS_FILTER_UPDATED = "WORKING_TENANTS_FILTER_UPDATED";
export const WORKING_TENANTS_FILTER_CLEARED = "WORKING_TENANTS_FILTER_CLEARED";
export const WorkingFilterProductTypes: string[] = ["All", "CMA", "CPA"];
const TENANTS_LIST_FILTER_STARTED = "TENANTS_LIST_FILTER_STARTED";
const TENANTS_LIST_FILTER_COMPLETED = "TENANTS_LIST_FILTER_COMPLETED";

type WorkingFilterProduct = "All" | "CPA" | "CMA";

export type WorkingFilter = {
  filter: string;
  status: Status | undefined;
  limit?: number;
  offset?: number;
  type?: WorkingFilterType;
  products?: WorkingFilterProduct;
};

export type UpdateWorkingFilterPayload = Partial<WorkingFilter>;
export type FetchTenantsCompleted = Partial<GetTenantsResponse> & {
  error?: HttpClientErrorResponse;
  filter?: WorkingFilter;
  users?: GetTenantsResponse;
};

export const doUpdateWorkingFilter = (filter: UpdateWorkingFilterPayload): Action<UpdateWorkingFilterPayload> => ({
  type: WORKING_TENANTS_FILTER_UPDATED,
  payload: filter
});

const doStartFetchTenants = (): Action => ({
  type: FETCH_TENANTS_STARTED
});

const doCompleteFetchTenants = (
  response: GetTenantsResponse,
  filter?: WorkingFilter
): Action<FetchTenantsCompleted> => ({
  type: FETCH_TENANTS_COMPLETED,
  payload: { ...response, filter }
});

const doFailFetchTenants = (error: HttpClientErrorResponse): Action<FetchTenantsCompleted> => ({
  type: FETCH_TENANTS_COMPLETED,
  payload: { error }
});

const shouldFetchTenants = (state: RdxStoreState) => state.tenants.tenants!.status !== RdxFetchStatus.LOADING;

export type DoChangeListSettingsPayload = {
  paging: { page: number; pageSize: number };
};

export const doFetchTenants = (): ThunkAction => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RdxStoreState,
    { navApi }
  ): Promise<void> => {
    if (!shouldFetchTenants(getState())) {
      return;
    }

    dispatch(doStartFetchTenants());

    try {
      const state: RdxTenantsSubState | undefined = cloneDeep(getState().tenants.tenants);
      const filter: WorkingFilter | undefined = state ? state.workingFilter : undefined;
      const apiFilter = cloneDeep(filter);
      if (apiFilter && (apiFilter.status as any) === StatusFilterAllValue) {
        delete apiFilter.status;
      }
      if (apiFilter && (apiFilter.products as any) === StatusFilterAllValue) {
        delete apiFilter.products;
      }
      const response = await navApi.tenants.getTenants({
        ...apiFilter,
        limit: state ? state.paging.itemsPerPage : 50,
        offset: state ? (state.paging.page - 1) * state.paging.itemsPerPage : 0
      });
      if (response && response.total > 1) {
        response.tenants.sort((a, b) => a.name.localeCompare(b.name));
      }
      dispatch(doCompleteFetchTenants(response, filter));
    } catch (ex) {
      const error: HttpClientErrorResponse = ex;
      dispatch(doFailFetchTenants(error));
    }
  };
};

export const doChangeListSettings = (page: number, pageSize: number): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    if (getState().usersList.status === RdxFetchStatus.LOADING) {
      return;
    }
    const payload: DoChangeListSettingsPayload = {
      paging: { page, pageSize }
    };
    dispatch({
      type: TENANTS_LIST_SETTINGS_CHANGED,
      payload
    });
    dispatch(doFetchTenants());
  };
};

export const doResetListSettings = (): ThunkAction => doChangeListSettings(DEFAULT_LIST_PAGE, DEFAULT_LIST_SIZE);

export const doApplyFilter = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => RdxStoreState): Promise<void> => {
    if (getIsTenantsListFilterEqualsToAppliedFilter(getState()) || getIsTenantsListLoading(getState())) {
      return;
    }
    dispatch({
      type: TENANTS_LIST_FILTER_STARTED
    });
    const state = getState().tenants.tenants;
    if (state) {
      const newFilter = cloneDeep(state.workingFilter);
      try {
        dispatch(doFetchTenants());
        dispatch({
          type: TENANTS_LIST_FILTER_COMPLETED,
          payload: {
            filter: newFilter
          }
        });
      } catch (ex) {
        dispatch({
          type: TENANTS_LIST_FILTER_COMPLETED,
          payload: {
            error: ex
          }
        });
      }
    }
  };
};

export const doClearFilter = (): ThunkAction => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch({
      type: WORKING_TENANTS_FILTER_CLEARED
    });

    dispatch(doApplyFilter());
  };
};
