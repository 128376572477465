import _ from "lodash";
import { createSelector } from "reselect";
import { SYSTEM_CONFIG } from "../../constants/system-config";
import { RdxUnauthState } from "../reducers/unauth/types";
import { RdxStoreState } from "../types/state";

export const getFeatureFlags = createSelector(
  (state: RdxStoreState) => state.unauth,
  (unauth: RdxUnauthState) => unauth.ffs
);

export const hasStudyPlansFF = createSelector(
  (state: RdxStoreState) => state.unauth,
  (unauth: RdxUnauthState) => _.includes(["dev"], SYSTEM_CONFIG.environment) || _.includes(unauth.ffs, "studyPlans")
);

export const shouldRenderLoginBanner = createSelector(
  (state: RdxStoreState) => state.unauth,
  (unauth: RdxUnauthState) => _.includes(unauth.ffs, "loginBanner")
);
