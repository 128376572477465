import { Alert, Typography } from "antd";
import { createBrowserHistory } from "history";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { AlertsToasts } from "../components/AlertsToasts";
import AlertsReader from "../pages/AlertsReader";
import AlertsWriter from "../pages/AlertsWriter";
import Dashboard from "../pages/Dashboard";
import Eula from "../pages/Eula";
import ForgotPassword from "../pages/ForgotPassword";
import Login from "../pages/Login";
import MyAccount from "../pages/MyAccount";
import { Partners } from "../pages/Partners";
import { Students } from "../pages/Students";
import StudentsAdd from "../pages/Students/StudentsAddForm";
import { StudyPlans } from "../pages/StudyPlans";
import { StudyPlansEditor } from "../pages/StudyPlans/StudyPlansEditor";
import { StudyPlansViewer } from "../pages/StudyPlans/StudyPlansViewer";
import Tags from "../pages/Tags";
import { TenantDetails } from "../pages/Tenant";
import { TenantEdit } from "../pages/TenantEdit";
import { TenantNew } from "../pages/TenantNew";
import { Tenants } from "../pages/Tenants";
import { Users } from "../pages/Users";
import UsersEditor from "../pages/UsersEditor";
import { doFetchMySelf } from "../redux/actions/myself/fetch";
import { doFetchFeatureFlags } from "../redux/actions/unauth";
import { getHasAcceptedEula } from "../redux/selectors/accepted-eula";
import { RxAppRoutesStatus, getAppRoutesStatus } from "../redux/selectors/app-routes-status";
import { hasStudyPlansFF, shouldRenderLoginBanner } from "../redux/selectors/ffs";
import { getHasPartnersPermission } from "../redux/selectors/permissions/has-manage-partners";
import { getHasTagsPermission } from "../redux/selectors/permissions/has-manage-tags";
import { getHasTenantsPermission } from "../redux/selectors/permissions/has-manage-tenants";
import { getHasUsersPermission } from "../redux/selectors/permissions/has-manage-users";
import { getIsSystemAdmin } from "../redux/selectors/permissions/is-system-admin";
import { routes } from "../routes";

// -------------------------------------------------------------------------------------------------
// - Create History
// -------------------------------------------------------------------------------------------------

const appHistory = createBrowserHistory({ window });

// -------------------------------------------------------------------------------------------------
//  Hooks
// -------------------------------------------------------------------------------------------------

const useLoginBanner = (status: RxAppRoutesStatus) => {
  const showLoginBanner = useSelector(shouldRenderLoginBanner);

  if (_.isEqual(RxAppRoutesStatus.LOADING, status)) {
    return false;
  }

  return (
    _.isEqual(RxAppRoutesStatus.UNAUTHENTICATED, status) &&
    _.isEqual(window.location.pathname, "/login") &&
    showLoginBanner
  );
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const Router = () => {
  const dispatch = useDispatch<any>();
  const routeStatus = useSelector(getAppRoutesStatus);
  const hasTenantsPermission = useSelector(getHasTenantsPermission);
  const hasPartnersPermission = useSelector(getHasPartnersPermission);
  const hasTagsPermission = useSelector(getHasTagsPermission);
  const hasUsersPermission = useSelector(getHasUsersPermission);
  const hasAcceptedEula = useSelector(getHasAcceptedEula);
  const isSystemAdmin = useSelector(getIsSystemAdmin);
  const hasStudyPlans = useSelector(hasStudyPlansFF);
  const showLoginAlert = useLoginBanner(routeStatus);

  React.useEffect(() => {
    dispatch(doFetchFeatureFlags());
    dispatch(doFetchMySelf());
  }, []);

  return (
    <React.StrictMode>
      {showLoginAlert ? (
        <Alert
          banner
          type="error"
          showIcon={false}
          closable={false}
          className="bg-[#F399AE]"
          message={
            <div style={{ color: "#13133F !important" }}>
              <Typography.Paragraph strong>
                Becker Navigator has been upgraded to support single sign-on.
              </Typography.Paragraph>
              <Typography.Paragraph>
                <Typography.Text>
                  If your Becker CPA/CMA Exam Review account uses the same email as your Navigator account, you can now
                  sign in with your Exam Review credentials.
                </Typography.Text>
                <br />
                <Typography.Text>
                  If you don’t have an Exam Review account, please reset your password using the{" "}
                  <Typography.Text strong>`Forgot Password`</Typography.Text> link on this page.
                </Typography.Text>
              </Typography.Paragraph>
            </div>
          }
        />
      ) : null}

      <HistoryRouter history={appHistory as any}>
        {_.isEqual(RxAppRoutesStatus.LOADING, routeStatus) ? null : _.isEqual(
            RxAppRoutesStatus.UNAUTHENTICATED,
            routeStatus
          ) ? (
          <Routes>
            <Route path={routes.login.path} element={<Login />} />
            <Route path={routes.forgotPassword.path} element={<ForgotPassword />} />
            <Route path="*" element={<Navigate to={routes.login.path} replace />} />
          </Routes>
        ) : _.isEqual(RxAppRoutesStatus.APP_READY, routeStatus) && !hasAcceptedEula ? (
          <Routes>
            <Route path={routes.eula.path} element={<Eula />} />
            <Route path="*" element={<Navigate to={routes.eula.path} replace />} />
          </Routes>
        ) : _.isEqual(RxAppRoutesStatus.APP_READY, routeStatus) && hasAcceptedEula ? (
          <>
            <AlertsToasts />
            <Routes>
              <Route path={routes.students.path} element={<Students />} />
              <Route path={routes.dashboard.path} element={<Dashboard />} />
              <Route path={routes.studentsAdd.path} element={<StudentsAdd />} />
              <Route path={routes.studentDetails.path} element={<Students showStudentDetails />} />

              {hasStudyPlans ? (
                <>
                  <Route path={routes.studyPlans.path} element={<StudyPlans />} />
                  <Route path={routes.studyPlansEdit.path} element={<StudyPlansEditor />} />
                  <Route path={routes.studyPlansNew.path} element={<StudyPlansEditor />} />
                  <Route path={routes.studyPlansPreview.path} element={<StudyPlansViewer />} />
                </>
              ) : null}

              <Route path={routes.tenantUsers.path} element={<Users />} />
              <Route path={routes.profile.path} element={<MyAccount />} />

              {hasUsersPermission
                ? [
                    <Route key="user-1" path={routes.users.path} element={<Users />} />,
                    <Route key="user-2" path={routes.usersNew.path} element={<UsersEditor />} />,
                    <Route key="user-3" path={routes.userDetails.path} element={<UsersEditor />} />
                  ]
                : null}

              {hasTagsPermission ? ( //
                <Route path={routes.tags.path} element={<Tags />} />
              ) : null}

              {hasTenantsPermission
                ? [
                    <Route key="tenant-p" path={routes.tenants.path} element={<Tenants />} />,
                    <Route key="tenant-s" path={routes.tenant.path} element={<TenantDetails />} />,
                    <Route key="tenant-n" path={routes.tenantNew.path} element={<TenantNew />} />,
                    <Route key="tenant-e" path={routes.tenantEdit.path} element={<TenantEdit />} />
                  ]
                : null}

              {hasPartnersPermission ? ( // Parters
                <Route path={routes.partners.path} element={<Partners />} />
              ) : null}

              {isSystemAdmin ? ( // Alerts Writer
                <Route path={routes.alertsWriter.path} element={<AlertsWriter />} />
              ) : null}

              {_.map(routes.alertsReader.paths, path => (
                <Route key={path} path={path} element={<AlertsReader />} />
              ))}

              <Route path="*" element={<Navigate to={routes.dashboard.path} replace />} />
            </Routes>
          </>
        ) : null}
      </HistoryRouter>
    </React.StrictMode>
  );
};
