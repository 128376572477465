import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getActiveUser } from "../../../redux/selectors/active-user";
import { getUserRoles } from "../../../redux/selectors/roles";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useEditCreateSpFlag = () => {
  const uCur = useSelector(getActiveUser);
  const uRol = useSelector(getUserRoles);
  return React.useMemo(
    () =>
      _.chain(uRol)
        .filter(ur => _.isEqual(ur.id, uCur?.roleId))
        .head()
        .thru(r =>
          _.includes(
            [
              "Admin",
              "Sys Admin",
              "Tenant Admin"
              //
            ],
            r.name
          )
        )
        .value(),
    [uCur?.roleId, JSON.stringify(uRol)]
  );
};
