export interface RosterStudentFilter {
  activeSection?: string;
  behindSchedule?: boolean;
  studyInLessThanDays?: number;
  hasReportedScores?: boolean;
  hasStudyPlan?: boolean;
  isExcluded?: boolean;
  isFollowed?: boolean;
  lastActivity?: string;
  licenseExpireInLessThanDays?: number;
  licenseExpireInMoreThanDays?: number;
  licensePurchaseDateMax?: string;
  licensePurchaseDateMin?: string;
  passedMinParts?: number;
  passedParts?: number;
  passedSection?: string;
  reportedSection?: string;
  searchFields?: string[];
  searchTerm?: string;
  studentIds?: number[];
  studyTimeLessThanSecs?: number;
  studyTimeMoreThanSecs?: number;
  studyPlanId?: number;
  tagIds?: number[];
  tagsNegate?: boolean;
  tenantId?: number;
}

export interface StudentRosterDetails {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  firstActivity?: number;
  lastActivity?: number;
  isExcluded?: boolean;
  licensePurchaseDate?: string; // lowest expiration date
  associationType: string; // ecomm, manual
  studyTimeSecs?: number;
  licenseExpirationDate?: Date; // latest expiration date. Empty if none or unlimited.
  licenseUnlimited: boolean;
  followed?: boolean;
  studyPlan: {
    sections: Array<{
      sectionAltId: string;
      examDate: string;
      isActualExamDate: boolean;
      isBehindSchedule: boolean;
    }>;
    hasStudyPlan: boolean;
    isBehindSchedule: boolean;
  };
  tenants?: Array<{
    id: number;
    association: string;
    name: string;
    isExcluded: boolean;
  }>;
}

export interface ReportTaskResponse {
  taskToken: string;
}

export enum SimulatedExamsReportOption {
  FirstAttempt = "first",
  LastAttempt = "last",
  BestAttempt = "best"
}

export type CreateStudentRosterResponse = {
  token: string;
  total: number;
};

export type GetStudentRosterListingOfStudentsResponse = {
  students: StudentRosterDetails[];
  total: number;
};
