import React from "react";
import { StudyPlanEditorFields } from "../useEditorFields";
import { StudyCalendarUtils } from "../utils/StudyCalendarUtils";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

type WeekDaysInfoValue = {
  title: string;
  value:
    | {
        weeksBetween: number;
        daysBetween: number;
      }
    | undefined;
};

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useWeekDaysInfo = (e: StudyPlanEditorFields): WeekDaysInfoValue =>
  React.useMemo((): WeekDaysInfoValue => {
    const info = StudyCalendarUtils.buildWeekDaysInfo(e.startDate.value, e.endDate.value!);
    return {
      title: info && info.daysBetween <= 15 ? "Days of study" : "Weeks of study",
      value: info
    };
  }, [
    e.startDate.value,
    e.endDate.value
    //
  ]);
