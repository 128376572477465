import { Flex } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import _ from "lodash";
import { CellRenderInfo } from "rc-picker/lib/interface";
import React from "react";
import { StudyPlanCalendar } from "../../../../../../services/nav-api/studyPlans/model";
import { StudyCalendarUtils } from "../../../../hooks/useStudyPlanEditor/utils/StudyCalendarUtils";
import { StudyPlanContext } from "../../../hooks/useStudyPlanSummary";
import { CalendarDayCellTasks } from "./CalendarDayCellTasks";
import { CalendarDayContext } from "./CalendarDayContext";
import { CalendarCellUtils } from "./CalendarDayUtils";

// -------------------------------------------------------------------------------------------------
// - Renderer
// -------------------------------------------------------------------------------------------------

export const fullCellRenderer =
  (calendar: StudyPlanCalendar<dayjs.Dayjs>[]) => (day: dayjs.Dayjs, info: CellRenderInfo<dayjs.Dayjs>) => {
    const curDay = info.today.isSame(day, "day");
    const dayDur = StudyCalendarUtils.getStudyTimePerDayFmt(day, calendar);

    return (
      <div
        className={classNames(
          `${info.prefixCls}-cell-inner`,
          `${info.prefixCls}-calendar-date`,
          {
            [`${info.prefixCls}-calendar-date-today`]: curDay
          }
          //
        )}
      >
        <Flex className={`${info.prefixCls}-calendar-date-value`}>
          <strong>{day.date()}</strong>
          <div className="grow" />
          {!_.isNil(dayDur) ? (
            <small>
              <i>{dayDur}</i>
            </small>
          ) : null}
        </Flex>

        <div className={`${info.prefixCls}-calendar-date-content`}>
          <StudyPlanContext.Consumer>
            {plan => (
              <CalendarDayContext.Provider value={CalendarCellUtils.calculateDateInfo(day, plan)}>
                <CalendarDayCellTasks />
              </CalendarDayContext.Provider>
            )}
          </StudyPlanContext.Consumer>
        </div>
      </div>
    );
  };
