export const EN_MESSAGES = {
  Any: "Any",
  HttpAccessForbiddenMsg: "You don't have permission to access this resource",
  HttpAccessForbiddenStatus: "Access forbidden",
  HttpEdgeBugFixLoginError: "Invalid username or password please try again.",
  HttpErrNetworkMsg: "A network error has occurred. Please check your network connection.",
  HttpErrNetworkTitle: "Attention",
  HttpInvalidJsonResponse: "Unable to parse JSON response",
  PageNotFound: "Page not found",
  LoginUsername: "Username",
  LoginPassword: "Password",
  LoginKeepMeSignedIn: "Remember me",
  LoginTitle: "Sign In",
  LoginButtonCaption: "Sign In",
  LogoutTitle: "Logout",
  LogoutConfirmTitle: "Are you sure you want to logout?",
  CopyrightMsg: "© Becker Professional Education Corporation. All rights reserved.",
  LoginGenericErrorMessage: "Something went wrong. User login failed.",
  ApiFailureModalGenericTitle: "Error",
  ApiFailureModalGenericMessage: "Something went wrong while fetching data.",
  FilterSearchPlaceholder: "Search in email and name ",
  FilterSearchPlaceholderWMinChars: "Search in email and name. Minimum {0} characters.",
  FilterSearchNamePlaceholder: "Search in name ",
  ApiFailureMultipleSession:
    "There are multiple sessions active for the same user. Needs to reload page causing all current state to be lost.",
  ApiFailureMultipleSessionBtnCaption: "Reload",
  ApiFailureSessionExpired: "Due to a long period of inactivity, your session has expired.",
  ApiFailureSessionTitle: "Session Expired",
  ApiFailureSessionExpiredBtnCaption: "Return to Sign In",
  ApiFailureModalGenericCloseBtnCaption: "Close",
  UnlimitedEnrollmentTableCell: "Unlimited",
  PerfDashReportLabel: "Performance Dashboard",
  PerfDashFrReportLabel: "Performance Dashboard (Final Review)",
  ExamTrackerReportLabel: "Exam Tracker",
  StudentsListLabel: "Students List",
  PerfDashReportLoading: "Generating Report",
  PerfDashReportSuccess: "Report has been downloaded",
  PerfDashReportFailed: "Something went wrong while generating the report.",
  TenantTagsDeleteCategory: "There are students related to this category sub tags.",
  TenantTagsDeleteTag: "There are students related to this tag or sub tags.",
  TenantTagsDeleteConfirmation: "Are you sure you want to delete it?",
  MoveStudentsConfirmationMessageLine1:
    "This action will only move students directly linked to the tag and will not move students linked to descendants tags.",
  MoveStudentsConfirmationMessageLine2: `Are you sure you want to move {0} student(s) from tag {1} to tag {2}?`,
  SubmitLabel: "Submit",
  CancelLabel: "Cancel",
  ConfirmLabel: "Confirm",
  AddLabel: "Add",
  AllLabel: "All",
  YesLabel: "Yes",
  NoLabel: "No",
  DeleteLabel: "Delete",
  EditLabel: "Edit",
  CloneLabel: "Clone",
  MoveStudentsLabel: "Move students",
  CreateNewLabel: "New",
  TagNamePlaceholder: "Tag name",
  AddNewRootLevelTag: "New category",
  CantDropMaxLevelReached: "Tags hierarchy is limited to 4 levels",
  EditStudentTitle: "Student Edit",
  RenderContentErrorTitle: "This page failed to render",
  RenderContentErrorSubTitle: "Please try again later or contact product support.",
  RenderContentErrorDescTitle: "Error details:",
  UnknownError: "Unknown error",
  ResendInvite: "Resend Invite",
  ResendInviteConfirmation: "Are you sure that you want to resend user invite?",
  ResendInviteSuccess: "Invited resent with success",
  AccountRegister: "Register",
  AccountRegisterSuccess: "Account created with success",
  InviteNoToken: "No token provided",
  InviteNotFound: "Invite not found",
  RootLevelTagsInfo:
    "Level one tags (root tags) work as categories and can’t be used to assign students/users or to filter the list of students.",
  RootLevelTagsInfo2: "Tags are automatically alphabetized.",
  PartnerStatusTitle: "Partner status",
  PartnerStatusSuccess: "Partner status changed with success",
  TenantCPATagFormTitle: "CPA Tag Form",
  TenantTagsTitle: "Tags",
  Success: "Success",
  Warning: "Warning",
  Error: "Error",
  TenantPartnerSuccess: "Partner added to tenant with success",
  TenantPartnerRemoveSuccess: "Partner removed with success",
  TenantPartnerRemove: "Are you sure that you want to delete this partner?",
  BulkChangeSelectedItemsMessage: "Bulk change for {0} student(s).",
  GenerateReportMessage: "Generate report for {0} student(s).",
  BulkChangeModalTitle: "Bulk Change",
  BulkChangeConfirmationPromptLine1:
    "This operation will edit the field <strong>{0}</strong> of <strong>{1}</strong> student(s).",
  BulkChangeConfirmationPromptLine2: "Are you sure you want to proceed?",
  ForgotPasswordBtnCaption: "Submit",
  ReturnToSignInBtnCaption: "Back to Login",
  GenericRequestFailedDefaultMessage: "Something went wrong. Please try again later",
  ForgotPasswordSuccess:
    "Instructions on how to change your password were sent by email to {0}. Follow instructions to reset your password.",
  ChangePasswordSuccess: "Password changed with success",
  OldPasswordMissing: "Current Password must be defined",
  NewPasswordMissing: "New Password must be defined",
  ChangePassword: "Change Password",
  OldPassword: "Current Password",
  NewPassword: "New Password",
  RetypeNewPassword: "Retype New Password",
  AssignPartnerToTenant: "Assign Partner to Tenant",
  Tenants: "Tenants",
  Tenant: "Tenant",
  Edit: "Edit",
  AssignPartner: "Assign Partner",
  Partners: "Partners",
  Details: "Details",
  SetNewPasswordTitle: "Set a new password",
  SetPasswordSuccess: "Your new password has been successfully set",
  UserCreatedSuccess: "User created with success",
  UserCreatedError: "Error creating user",
  PasswordMinChars: "Your password must have at least 8 characters.",
  Status: "Status",
  Disabled: "Disabled",
  Enabled: "Enabled",
  Apply: "Apply",
  Clear: "Reset",
  CPADashboard: "Welcome to CPA Navigator",
  CMADashboard: "Welcome to CMA Navigator",
  AddStudents: "Add Students",
  StudentsAddTitle: "Add Students: Finished",
  StudentsAddClose: "Close",
  StudentsAddCountSingular: "{0} student account was added.",
  StudentsAddCountPlural: "{0} student accounts were added.",
  StudentsAddTab_Unmatched: "Not Found",
  StudentsAddTab_Skipped: "Skipped",
  StudentsAddedSuccessfullyButWithExceptions_Unmatched:
    "The following student accounts were not found. Please confirm if they are valid accounts.",

  StudentsAddedSuccessfullyButWithExceptions_Skipped:
    "This action skipped adding the following student’s accounts because their account already exists in Navigator. If tags will be selected, they will be added to each student account.",

  StudentsAddError: "Something went wrong while adding the students",
  StudentsUnmatchedError: "The following emails were not found. Please confirm if they are valid student accounts.",
  StudentsAddNotFound: "Accounts not found in Becker CPA:",
  StudentsAddPlaceholder: "Please enter one or more email addresses separated with spaces, commas or line breaks.",
  StudentEmails: "Student Emails",
  StudentEmailsMustBeDefined: "Student Emails must be defined",
  Tags: "Tags",
  NewUser: "New User",
  ErrPageNeedsReload: "An error occurred, the page needs to be reloaded.",
  PartnersSelectPlaceholder: "Type partner name",
  PerformanceDashboard: "Performance Dashboard",
  PerformanceDashboardDescription: "Performance Dashboard has been downloaded.",
  ReportTitle: "Report",
  ReportSuccess: "Your report is ready. Download started.",
  ReportError: "Error generating report. Try again later.",
  ReportLoading: "Please wait while the report is being processed",
  FirstAttempt: "First Attempt",
  LastAttempt: "Last Attempt",
  BestAttempt: "Best Attempt",
  BulkChange: "Bulk Change",
  All: "All",
  CurrentPage: "Current Page",
  Actions: "Actions",
  Reports: "Reports",
  SelectTagPlaceholder: "Select tag",
  DeleteTagItemTooltipMsg: "Exclude from list",
  StudentEntryTagsErrorTitle: "CPA Tag Form",
  StudentEntryTagsErrorDescription: "Something went wrong while submitting the CPA Tag Form. Please, try again.",
  ReportsModalTitle: "Reports",
  ReportCreateBtn: "Create",
  SimulatedExamsScoreReportLabel: "Simulated Exams Score",
  SimulatedExamsScoreFrReportLabel: "Simulated Exams Score (Final Review)",
  SimulatedExamsAnswersReportLabel: "Simulated Exams Answers",
  MiniExamsAnswersReportLabel: "Mini Exams Answers",
  SimulatedExamPerformanceReportLabel: "Simulated Exams Performance",
  HomeworkMCQFirstAttemptLabel: "Homework MCQ First Attempt",
  HomeworkPerformanceReportLabel: "Homework Performance",
  ReportMaxEntries: "The report is limited to the current page (up to {0} students)",
  NavLogout: "Logout",
  NavDashboard: "Dashboard",
  NavStudents: "Students",
  NavStudyPlans: "Study Plans",
  NavSettings: "Settings",
  NavTenants: "Tenants",
  NavPartners: "Partners",
  NavUsers: "Users",
  NavTags: "Tags",
  NavNotifications: "Notifications",
  NavMyAccount: "My Account",
  NavUserGuide: "User Guide",
  NavPrivacy: "Privacy",
  NavPrivacyPolicy: "Privacy Policy",
  NavCookiePolicy: "Cookie Policy",
  NavCookiePrefCenter: "Cookie Preference Center",
  ContactUs: "Contact Us",
  PrivacyPolicy: "Privacy Policy",
  CookiePolicy: "Cookie Policy",
  CookiePreferenceCenter: "Cookie Preference Center",
  FilteredBy: "Filtered by",
  ResetFilters: "Reset Filters",
  FilterTagTags: "Tags: {0}",
  WithoutTags: "without",
  FilterMetricFollowed: "Followed students",
  FilterTagSelectedSection: "Selected Section: {0}",
  FilterTagLastActivitySection: "Last Activity: {0}",
  FilterTagExamTracker: "Exam Tracker: {0}",
  FilterTagFollowedStudents: "Followed",
  FilterTagHiddenStudents: "{0} hidden students",
  FilterTagLicense: "License: {0}",
  FilterTagStudyPlanValue: "Study Plan: {0}",
  FilterTagPurDate: "Purchased Date: {0}",
  FilterTagStudyPlan: "Study Plan: {0}",
  FilterTagStudyTime: "Study Time: {0}",
  FilterTagTenant: "Tenant: {0}",
  StudentsPageTitle: "Students",
  FiltersDrawerTitle: "Filters",
  FilterBtnLabel: "Filters",
  SubmitNewPassword: "Save New Password",
  PasswordMinCharsText: "Password must have at least 8 characters",
  PasswordDidNotMatchText: "Passwords do not match",
  Last7Days: "Last 7 days",
  Last30Days: "Last 30 days",
  Month: "Month",
  ExamDate: "Exam Date",
  DailyStudyTime: "Daily Study Time",
  UnitStudyTime: "Unit Study Time",
  FollowThisStudent: "Follow this student",
  UnfollowThisStudent: "Unfollow this student",
  Total: "Total",
  Other: "Other",
  TotalTime: "total time",
  AverageDailyTime: "avg daily time",
  Hour: "hour",
  Hours: "hours",
  ExpiringSoon: "Expiring Soon",
  Expired: "Expired",
  ChooseReport: "Choose a report",
  LaunchOptions: "Launch Options",
  Attempts: "Attempts",
  Options: "Options",
  IncludeModules: "Include Modules",
  MiniExamScoreReportLabel: "Mini Exams Score",
  MiniExamPerformanceReportLabel: "Mini Exams Performance",
  StudyTimeReportLabel: "Study Time",
  TimesheetReportLabel: "Timesheet",
  StudentsActivity: "Students Activity",
  ExamTracker: "Exam Tracker",
  MoreDetails: "More Details",
  PassRateBySection: "Pass Rate by section",
  PassRateBySectionInfo:
    "This card shows the exam pass rate for each part of the {0} Exam and represents the success rate of the active students that took each part of the {0} Exam.",
  PassedSection: "Passed {0}",
  PassedAllParts: "Passed all parts",
  NoExamTrackerData: "No exam tracker data",
  PassedAnyPart: "Passed any part",
  AboutToLoseOrLostCredits: "About to lose or lost credits",
  Licenses: "Licenses",
  ExpiredInMoreThan3Months: "Expire > 3 months",
  ExpiredInLessThan3Months: "Expire <= 3 months",
  ExpiredLicenses: "Expired licenses",
  StudyPlanUsage: "Study plan usage",
  StudyPlanPerformance: "Study plan performance",
  StudentsWithoutStudyTime: "Students without study time",
  FollowedStudents: "Followed Students",
  StudentsWithStudyPlan: "with study plan",
  StudentsTotalOnTrack: "total on track",
  WithStudyPlan: "With study plan",
  WithoutStudyPlan: "Without study plan",
  OnTrack: "On track",
  BehindSchedule: "Behind schedule",
  None: "None",
  ActiveStudents: "Active Students",
  StudentsBySection: "Students By Section",
  Name: "Name",
  Users: "Users",
  Students: "Students",
  TenantsByType: "Tenants By Type",
  Top5Firms: "Top 5 Firms",
  Firm: "Firm",
  University: "University",
  Government: "Government",
  Society: "Society",
  InternationalPartner: "International Partner",
  MiniExamNr: "Mini Exam {0}",
  SelectedSectionFilter: "Selected Section",
  LastActivityFilter: "Last Activity",
  StudyTime: "Study Time",
  AlertsFormAddTitle: "Add Notification",
  AlertsFormEditTitle: "Edit Notification",
  AlertsFormItemTitle: "Title",
  AlertsFormItemTitlePlaceholder: "Notification Title",
  AlertsFormItemMessage: "Message",
  AlertsFormItemDatesRange: "Publish & Expiration Dates",
  AlertsFormItemPublishDatePlaceholder: "Publish Date",
  AlertsFormItemExpirationDatePlaceholder: "Expiration Date",
  AlertsFormItemSendEmail: "Send Email?",
  AlertsFormCancel: "Cancel",
  AlertsFormSave: "Save",
  AlertsFiltersReset: "Reset",
  AlertsFiltersApply: "Apply",
  AlertsTableColumnTitle: "Title",
  AlertsTableColumnPublishDate: "Publish Date",
  AlertsTableColumnExpirationDate: "Expiration Date",
  AlertsTableColumnEmail: "Email",
  AlertsTableColumnStatus: "Status",
  AlertsTableColumnActions: "Actions",
  AlertsDeleteMessage: "Are you sure you want to delete this notification?",
  AlertsExpireMessage: "Are you sure you want to expire this notification?",
  AlertsDeleteTooltip: "Delete Notification",
  AlertsEditTooltip: "Edit Notification",
  AlertsExpireTooltip: "Expire Notification",
  TriggerForgotPassword: "Trigger Forgot password",
  Delete: "Delete",
  MethodNotAllowed: "Method Not Allowed",
  UserNotDisabled: "User Not Disabled",
  DeleteUserQuestion: "Are you sure that you want to delete this user?",
  GetNotificationEmails: "Get notification e-mails",
  UnsubscribeEmailNotifications: "Unsubscribe email notifications",
  NotificationsCenter: "Notification Center",
  Notifications: "Notifications",
  Information: "Information",
  Permissions: "Permissions",
  ChangeSettingsSuccess: "Settings changed with success",
  Save: "Save",
  FilterTagRole: "Role: {0}",
  FilterTagStatus: "Status: {0}",
  FilterTagCreationDate: "Creation Date: {0}",
  FilterTagLastLoginDate: "Last Login Date: {0}",
  Day: "Day",
  Unit: "Unit",

  ExamTrackerReportDescription: "Export of the Exam Tracker data (exam dates and scores) for each selected student.",
  HomeworkMCQFirstAttemptDescription: "First attempt response on each Homework MCQ.",
  HomeworkPerformanceReportDescription:
    "Average performance of the group of students on each course section, unit, and module. The information is split between the different content types (MCQ & TBS) and also shows the difference in performance between the first and last attempts.",
  MiniExamPerformanceReportDescription:
    "Average performance of the group of students on each Mini Exam, with data drill down to units and modules. The information is split between the different content types and considers the results for the first, last, or best attempt of each student (chosen before launching the report).",
  MiniExamScoreReportDescription:
    "Scores export (first, last, or best) of the Mini Exams, with reference to the time spent and date of execution. Includes individual sheets with the exam details for each student.",
  PerfDashReportDescription:
    "The performance dashboard summary shows the current section, unit and module progress and Exam Day Ready level for each student. Individual student sheets show detailed progress information down to the module level.",
  PerfDashFrReportDescription:
    "The Final Review performance dashboard shows the current section, unit, and module progress for each student.",
  SimulatedExamsAnswersReportDescription:
    "Distribution of the student answers to each question, with the right answer as reference. Includes individual sheets for each student with the answers they gave to each Simulated Exam question.",
  MiniExamsAnswersReportDescription:
    "Distribution of the student answers to each question, with the right answer as reference. Includes individual sheets for each student with the answers they gave to each Mini Exam question.",
  SimulatedExamPerformanceReportDescription:
    "Average performance of the group of students on each Simulated Exam, with data drill down to units and modules. The information is split between the different content types and considers the results for the first, last, or best attempt of each student (chosen before launching the report).",
  SimulatedExamsScoreReportDescription:
    "Scores export (first, last, or best) of the Simulated Exams, with reference to the time spent and date of execution. Includes individual sheets with the exam details of each student.",
  SimulatedExamsScoreFrReportDescription:
    "Scores export (first, last, or best) of the Simulated Exams, with reference to the time spent and date of execution. Includes individual sheets with the exam details of each student.",
  StudentsListDescription:
    "Basic student export, including all fields visible on the list, as well additional information like affiliation and study time.",
  StudyTimeReportDescription:
    "Shows all the accumulated time spent on studying each Unit, Module, and Exam. Module details can be optionally included.",
  TimesheetReportDescription:
    "Shows the number of hours students spent on the course for each day of the selected period.",

  SelectMultipleAttempts: "Exam Attempts",
  SelectSimulatedExamNumber: "Simulated Exam",
  SelectMiniExamNumber: "Mini Exam",
  SelectSingleSection: "Section",
  SelectMultipleSections: "Sections",
  SelectSectionDetails: "Level of details",
  SectionDetailsName_Sections: "Sections",
  SectionDetailsName_Units: "Units",
  SectionDetailsName_UnitsAndExams: "Units and Exams",
  SectionDetailsName_Modules: "Modules",
  SelectProductSectionUnit: "Select a {0} Section and Unit",
  Hidden: "Hidden",
  Visible: "Visible",

  ReportTypeMessage_EXAM_TRACKER_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_HOMEWORK_MCQ_FIRST_ATTEMPT_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_HOMEWORK_PERFORMANCE_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_MINI_EXAMS_PERFORMANCE_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_MINI_EXAMS_SCORE_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_PERFORMANCE_DASHBOARD_FR_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_PERFORMANCE_DASHBOARD_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_SIMULATED_EXAMS_ANSWERS_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_SIMULATED_EXAMS_PERFORMANCE_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_MINI_EXAMS_ANSWERS_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_SIMULATED_EXAMS_SCORE_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_SIMULATED_EXAMS_SCORE_FR_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_STUDENTS_LIST_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_STUDY_TIME_REPORT: "Report is limited to a maximum of {0} students.",
  ReportTypeMessage_TIMESHEET_REPORT: "Report is limited to a maximum of {0} students.",

  CPAPreEvolutionEnabled: "CPA Pre-Evolution",
  CPAPreEvolutionDisabled: "CPA Pre-Evolution",

  ContentVersionSwitchTitleWhenDisabled: "Enable CPA pre-evolution progress?",
  ContentVersionSwitchMessageWhenDisabled:
    "Enabling this toggle restricts all Navigator data and reports to CPA pre-evolution content. Are you sure you want to proceed?",

  ContentVersionSwitchTitleWhenEnabled: "Disable CPA pre-evolution progress?",
  ContentVersionSwitchMessageWhenEnabled:
    "Disabling this toggle makes all Navigator data and reports report only on student progress on the new CPA Evolution sections. Are you sure you want to proceed?",
  ReportSettings: "Report settings",
  NeedHelp: "Need help?",
  StudentPerformance: "Student Performance",
  CreateStudyPlan: "New Study Plan",
  StudyPlansTitle: "Study Plans",
  NewStudyPlanTitle: "New Study Plan",
  EditStudyPlanTitle: "Edit Study Plan"
};
