import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import { ApplicationToastsContext } from "../../../../components/AppToasts";
import { sectionNameByAltId } from "../../../../constants/courses";
import { navApi } from "../../../../services/nav-api";
import { StudyPlanCalendar, StudyPlansListItem, StudyPlanTask } from "../../../../services/nav-api/studyPlans/model";
import { StudyCalendarUtils } from "../../hooks/useStudyPlanEditor/utils/StudyCalendarUtils";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type StudyPlanValue = {
  loading: boolean;
  loadingStatus: boolean;
  id?: number;
  tasks?: { [key: string]: StudyPlanTask };
  calendar?: StudyPlanCalendar<dayjs.Dayjs>[];
  error?: {
    message: string;
    status: string;
  };

  summary: {
    startDate?: dayjs.Dayjs;
    startDateFmt: string;
    endDate?: dayjs.Dayjs;
    endDateFmt: string;
    daysOff?: dayjs.Dayjs[];
    created: string;

    lockUntil: dayjs.Dayjs | null;
    lockUntilFmt: string;

    name: string;
    section: string;
    tags: string[];
    studyDurationTitle: string;
    studyDuration: number | null;
    tenantId?: number;
    tenantName?: string;
    tagIds?: number[];
    status?: StudyPlansListItem["status"];
    range?: [dayjs.Dayjs, dayjs.Dayjs];
  };

  onStatusChanged: (status: StudyPlansListItem["status"]) => void;
};

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

export const StudyPlanContext = React.createContext<StudyPlanValue>({
  loading: true,
  loadingStatus: true,
  onStatusChanged: _.noop,
  summary: {
    created: "",
    endDateFmt: "",
    lockUntil: null,
    lockUntilFmt: "",
    name: "",
    section: "",
    startDateFmt: "",
    tags: [],
    studyDurationTitle: "",
    studyDuration: null
  }
});

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useStudyPlan = (): StudyPlanValue => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = React.useState<boolean>(false);
  const [studyPlan, setStudyPlan] = React.useState<StudyPlansListItem | null>(null);
  const [error, setError] = React.useState<StudyPlanValue["error"]>();

  const toastsApi = React.useContext(ApplicationToastsContext);
  const params = useParams<{ id?: string }>();

  // -----------------------------------------------------------------------------------------------
  // - Callbacks
  // -

  const formatDate = React.useCallback((date: dayjs.Dayjs) => {
    return !_.isNil(date) ? date.format("MM/DD/YYYY") : "";
  }, []);

  // -----------------------------------------------------------------------------------------------
  // - Callbacks: Fetch Studyplans
  // -
  const fetchStudyPlan = React.useCallback(async (studyPlanId: number) => {
    try {
      setLoading(true);
      setStudyPlan(await navApi.studyPlans.fetchStudyPlanById(studyPlanId));
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  // -----------------------------------------------------------------------------------------------
  // - Effects
  // -
  React.useEffect(() => {
    if (!_.isNil(params.id)) {
      fetchStudyPlan(parseInt(params.id));
    }
  }, [params.id]);

  // -----------------------------------------------------------------------------------------------
  // - Ret val
  // -
  return {
    loading: loading,
    loadingStatus,
    error,

    ...React.useMemo(() => {
      const sdate = dayjs(studyPlan?.settings?.startDate);
      const edate = dayjs(studyPlan?.settings?.examDate);
      const cdate = dayjs(studyPlan?.created);
      const ldate = !_.isNil(studyPlan?.lockUntil) ? dayjs(studyPlan?.lockUntil) : null;
      const studyDurationInfo = StudyCalendarUtils.buildWeekDaysInfo(sdate, edate);

      return {
        id: studyPlan?.id,
        tasks: _.keyBy(studyPlan?.tasks, "id"),
        calendar: _.chain(studyPlan?.calendar)
          .map(ical => ({ ...ical, day: dayjs(ical.day) }))
          .value(),

        summary: {
          name: studyPlan?.name ?? "Study Plan",
          section: _.isNil(studyPlan?.sectionAltId) ? "" : sectionNameByAltId[studyPlan?.sectionAltId],

          startDate: sdate,
          endDate: edate,
          created: formatDate(cdate),
          lockUntil: ldate,

          startDateFmt: formatDate(sdate),
          endDateFmt: formatDate(edate),
          lockUntilFmt: ldate ? formatDate(ldate) : "-",

          daysOff: _.map(studyPlan?.settings?.daysOff, day => dayjs(day)),
          status: studyPlan?.status,
          weeksOfStudy: 0,
          tenantId: studyPlan?.tenant?.id,
          tenantName: studyPlan?.tenant?.name,
          tagIds: _.map(studyPlan?.tags, tag => tag.id),
          tags: _.map(studyPlan?.tags, tag => tag.name),

          studyDurationTitle:
            studyDurationInfo && studyDurationInfo.daysBetween <= 15 ? "Days of study" : "Weeks of study",

          studyDuration: studyDurationInfo
            ? studyDurationInfo.daysBetween <= 15
              ? studyDurationInfo.daysBetween
              : studyDurationInfo.weeksBetween
            : null,

          range: !_.some([sdate, edate], _.isNil) //
            ? ([sdate!, edate!] as [dayjs.Dayjs, dayjs.Dayjs])
            : undefined
        }
      };
    }, [JSON.stringify(studyPlan), error]),

    onStatusChanged: (status: StudyPlansListItem["status"]) => {
      if (!_.isNil(studyPlan?.id)) {
        (async () => {
          try {
            setLoadingStatus(true);
            await navApi.studyPlans.updateStudyPlansStatus(studyPlan?.id, status);

            setStudyPlan({ ...studyPlan, status });
          } catch (e) {
            toastsApi.error({
              message: "Error",
              description: e.message ?? e.title ?? "Unknown error"
            });
          } finally {
            setLoadingStatus(false);
          }
        })();
      }
    }
  };
};
