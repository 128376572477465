import { Divider, Popover, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";
import { CalendarDayCellDayOff } from "./CalendarDayCellDayOff";
import { CalendarDayCellEndDate } from "./CalendarDayCellEndDate";
import { CalendarDayCellStartDate } from "./CalendarDayCellStartDate";
import { CalendarDayCellTaskFinalReview } from "./CalendarDayCellTaskFinalReview";
import { CalendarDayCellTaskFlashcard } from "./CalendarDayCellTaskFlashcard";
import { CalendarDayCellTaskMiniExam } from "./CalendarDayCellTaskMiniExam";
import { CalendarDayCellTaskModule } from "./CalendarDayCellTaskModule";
import { CalendarDayCellTaskPracticeTest } from "./CalendarDayCellTaskPracticeTest";
import { CalendarDayCellTaskSimExam } from "./CalendarDayCellTaskSimExam";
import { CalendarDayContext } from "./CalendarDayContext";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

type TaskType = "start-date" | "end-date" | "day-off" | StudyPlanTask;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const TaskRenderer = (props: { task: TaskType }) =>
  _.isEqual(props.task, "start-date") ? (
    <CalendarDayCellStartDate />
  ) : _.isEqual(props.task, "end-date") ? (
    <CalendarDayCellEndDate />
  ) : _.isEqual(props.task, "day-off") ? (
    <CalendarDayCellDayOff />
  ) : _.isEqual((props.task as StudyPlanTask).type, "module") ? (
    <CalendarDayCellTaskModule task={props.task as StudyPlanTask} />
  ) : _.isEqual((props.task as StudyPlanTask).type, "flashcard") ? (
    <CalendarDayCellTaskFlashcard task={props.task as StudyPlanTask} />
  ) : _.isEqual((props.task as StudyPlanTask).type, "practicetest") ? (
    <CalendarDayCellTaskPracticeTest task={props.task as StudyPlanTask} />
  ) : _.isEqual((props.task as StudyPlanTask).type, "miniexam") ? (
    <CalendarDayCellTaskMiniExam task={props.task as StudyPlanTask} />
  ) : _.isEqual((props.task as StudyPlanTask).type, "simexam") ? (
    <CalendarDayCellTaskSimExam task={props.task as StudyPlanTask} />
  ) : _.isEqual((props.task as StudyPlanTask).type, "finalreview") ? (
    <CalendarDayCellTaskFinalReview task={props.task as StudyPlanTask} />
  ) : (
    <span>Unknown {(props.task as StudyPlanTask)?.id}</span>
  );

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellTasks = () => (
  <CalendarDayContext.Consumer>
    {({ tasks, day, isStartDate, isEndDate, isDayOff }) =>
      _.chain([
        ...(isStartDate ? ["start-date"] : []),
        ...(isEndDate ? ["end-date"] : []),
        ...(isDayOff ? ["day-off"] : []),
        ...tasks //
      ] as TaskType[])
        .map((t, i) => <TaskRenderer key={`task-${i}`} task={t} />)
        .thru(e => {
          return (
            <ul key="items">
              {e.length > 3 ? _.slice(e, 0, 2) : e}
              {e.length > 3 ? (
                <li key="more">
                  <Divider className="my-0 py-0 my-1" />
                  <Popover
                    content={<ul>{e}</ul>}
                    title={
                      <>
                        {day.format("MMM D, YYYY")}
                        <Divider className="py-0 my-0" />
                      </>
                    }
                  >
                    <Typography.Text strong>{e.length - 2} more</Typography.Text>
                  </Popover>
                </li>
              ) : null}
            </ul>
          );
        })
        .value()
    }
  </CalendarDayContext.Consumer>
);
