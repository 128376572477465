import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { StudyPlanContext } from "../../../hooks/useStudyPlanSummary";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type CurrentDateValue = {
  setDate: (day?: dayjs.Dayjs) => void;
  date?: dayjs.Dayjs;
};

// -------------------------------------------------------------------------------------------------
// - Context
// -------------------------------------------------------------------------------------------------

export const CurrentDateContext = React.createContext<CurrentDateValue>({ setDate: _.noop });

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useCurrentDate = (): CurrentDateValue => {
  const plan = React.useContext(StudyPlanContext);
  const [date, setDate] = React.useState<dayjs.Dayjs | undefined>();

  React.useEffect(() => {
    setDate(plan.summary.startDate);
  }, [plan.summary.startDate]);

  return { date, setDate };
};
