import { DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, CalendarMode, Col, Divider, Flex, Row, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { StudyPlanContext } from "../../../hooks/useStudyPlanSummary";
import { CurrentDateContext } from "../hooks/useCurrentDate";
import { MonthSelect } from "./MonthSelect";
import { YearSelect } from "./YearSelect";

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

const useHeader = () => {
  const cur = React.useContext(CurrentDateContext);
  const plan = React.useContext(StudyPlanContext);
  return {
    jumpPrevDisabled: cur.date?.clone().subtract(1, "month").isBefore(plan.summary.startDate!),
    jumpForwardDisabled: cur.date?.clone().add(1, "month").isAfter(plan.summary.endDate!),
    jumpStartDateDisabled: plan.summary.startDate?.isSame(cur.date, "day"),
    jumpEndDateDisabled: plan.summary.endDate?.isSame(cur.date, "day"),
    plan,
    cur
  };
};

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarHeader = (props: {
  value: dayjs.Dayjs;
  type: CalendarMode;
  onChange: (date: dayjs.Dayjs) => void;
  onTypeChange: (type: CalendarMode) => void;
}) => {
  const {
    cur,
    jumpForwardDisabled,
    jumpPrevDisabled,
    jumpStartDateDisabled,
    jumpEndDateDisabled,
    plan
    //
  } = useHeader();
  return (
    <div>
      <Row>
        <Col xs={6} />
        <Col xs={12}>
          <Flex vertical={false} gap={12} justify="center" align="center">
            <Button
              type="link"
              title="Start Date"
              icon={<DoubleLeftOutlined />}
              disabled={jumpStartDateDisabled}
              onClick={() => cur.setDate(plan.summary.startDate!)}
            />

            <Button
              type="link"
              title="Previous Month"
              icon={<LeftOutlined />}
              disabled={jumpPrevDisabled}
              onClick={() => {
                cur.setDate(cur.date?.clone().subtract(1, "month"));
              }}
            />

            <div className="text-center grow">
              <Typography.Title level={5} className="p-0 m-0">
                {props.value.format("MMMM D, YYYY")}
              </Typography.Title>
            </div>

            <Button
              type="link"
              title="Next Month"
              icon={<RightOutlined />}
              disabled={jumpForwardDisabled}
              onClick={() => cur.setDate(cur.date?.clone().add(1, "month"))}
            />

            <Button
              type="link"
              title="End Date"
              icon={<DoubleRightOutlined />}
              disabled={jumpEndDateDisabled}
              onClick={() => cur.setDate(plan.summary.endDate!)}
            />
          </Flex>
        </Col>
        <Col xs={6}>
          <Flex justify="end" gap={12}>
            <YearSelect
              validRange={plan.summary.range}
              value={cur.date!}
              onChange={v => {
                cur.setDate(v);
              }}
            />
            <MonthSelect
              validRange={plan.summary.range}
              value={cur.date!}
              onChange={v => {
                cur.setDate(v);
              }}
            />
          </Flex>
        </Col>
      </Row>
      <Divider />
    </div>
  );
};
