import { ReloadOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Form, Result, Space, Spin, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { BaseLayout } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { MESSAGES } from "../../i18n";
import { routes } from "../../routes";
import { Status } from "../../services/nav-api/types";
import { EmailField } from "./FieldForEmail";
import { FirstName } from "./FieldForFirstName";
import { LastName } from "./FieldForLastName";
import { NotificationsSection } from "./FieldForNotifications";
import { TenantAndRoleFields } from "./FieldForUserTenantAndRole";
import { useUserActions } from "./hooks/useFormActions";
import { FormControllerContext, FormControllerValue, userFormController } from "./hooks/userFormController";
import { UserStatus } from "./UserStatus";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const breadcrumb = (editinMode: boolean) => [
  {
    name: "Users",
    href: routes.users.resolve()
  },
  {
    name: editinMode ? "Details" : "New User"
  }
];

// -------------------------------------------------------------------------------------------------
// - Helpers
// -------------------------------------------------------------------------------------------------

const buildPageTitle = (ctx: FormControllerValue) =>
  !ctx.editingMode
    ? `New User`
    : `Edit ${
        !_.isNil(ctx.firstName.value) && !_.isNil(ctx.lastName.value)
          ? `${ctx.firstName.value} ${ctx.lastName.value} ${_.isEqual(Status.Disabled, ctx.serverUser.value?.status) ? "(Disabled)" : ""}`
          : "User"
      }`;

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

const UsersEditor = () => {
  // If userId is defined, then this editor enters editing mode
  const { id } = useParams<{ id: string }>();
  const userId = !_.isNil(id) ? parseInt(id!) : null;

  // Initialize form controller
  const formController = userFormController(userId);
  const userActions = useUserActions(formController);
  const navigate = useNavigate();

  return (
    <FormControllerContext.Provider value={formController}>
      <BaseLayout
        activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_USERS}
        breadcrumb={breadcrumb(formController.editingMode)}
      >
        <Spin spinning={formController.serverUser.isLoading}>
          <Typography.Title level={2}>{buildPageTitle(formController)}</Typography.Title>
          {formController.serverUser?.notFound ? (
            <Card>
              <Result
                status="404"
                title="404"
                subTitle="User not found"
                extra={
                  <Button
                    type="primary"
                    onClick={() => {
                      navigate(routes.users.path);
                    }}
                  >
                    Back to Users
                  </Button>
                }
              />
            </Card>
          ) : (
            <Form layout="vertical" name="create-user">
              <Flex vertical gap={24}>
                <Card title={MESSAGES.Information}>
                  <EmailField />
                  <FirstName />
                  <LastName />
                  <TenantAndRoleFields />
                </Card>

                <NotificationsSection />

                <Card>
                  <Flex>
                    <Space className="grow">
                      <Button
                        icon={<ReloadOutlined />}
                        disabled={userActions.inProgress}
                        onClick={() => {
                          navigate(routes.users.resolve());
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        loading={userActions.inProgress}
                        onClick={async () => {
                          await userActions.action();
                        }}
                      >
                        {formController.editingMode ? `Save` : `Create`}
                      </Button>
                    </Space>
                    <UserStatus />
                  </Flex>
                </Card>
              </Flex>
            </Form>
          )}
        </Spin>
      </BaseLayout>
    </FormControllerContext.Provider>
  );
};

// -------------------------------------------------------------------------------------------------
// - Default Export
// -------------------------------------------------------------------------------------------------

export default UsersEditor;
