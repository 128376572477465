import { FilterFilled, FilterOutlined, FilterTwoTone, ReloadOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Radio, Typography } from "antd";
import { ColumnProps } from "antd/es/table";
import { FilterDropdownProps } from "antd/es/table/interface";
import _ from "lodash";
import React from "react";
import { AltID, sectionNameByAltId } from "../../../../constants/courses";
import { useStudyPlanSections } from "../../hooks/useStudyPlanSections";
import { CreateColumnProps } from "./model";

// -------------------------------------------------------------------------------------------------
// - Column builder
// -------------------------------------------------------------------------------------------------

export const createSectionColumn = ({
  sort,
  paging,
  filters: { sectionFilter }
}: CreateColumnProps): ColumnProps<{}>[] => [
  {
    sorter: true,
    width: 100,
    sortOrder: _.isEqual(sort?.key, "sectionAltId") ? sort?.order : undefined,
    title: <Typography.Text ellipsis={true}>Section</Typography.Text>,
    render: (value: AltID) => <Typography.Text>{sectionNameByAltId[value]}</Typography.Text>,
    dataIndex: "sectionAltId",
    filterIcon: _.isEqual(sectionFilter.value, sectionFilter.defaultValue) ? (
      <FilterFilled />
    ) : (
      <FilterTwoTone twoToneColor="green" />
    ),

    filterDropdown: (props: FilterDropdownProps) => {
      const options = useStudyPlanSections();
      const [tmpSectionValue, setTmpSectionValue] = React.useState<AltID[] | null>(sectionFilter.value);

      React.useEffect(() => {
        setTmpSectionValue(sectionFilter.value);
      }, [props.visible]);

      return (
        <Flex vertical gap={12} style={{ padding: 12 }}>
          <Radio.Group
            style={{ display: "flex", flexDirection: "column" }}
            options={options}
            value={_.head(tmpSectionValue)}
            onChange={e => {
              setTmpSectionValue([e.target.value]);
            }}
          />

          <Divider style={{ padding: 0, margin: 0 }} />
          <Flex gap={6} justify="end">
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => {
                paging.setFirstPage();
                sectionFilter.setValue(sectionFilter.defaultValue);
                props.close();
              }}
            >
              Reset
            </Button>
            <Button
              size="small"
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => {
                paging.setFirstPage();
                sectionFilter.setValue(tmpSectionValue);
                props.close();
              }}
            >
              Apply
            </Button>
          </Flex>
        </Flex>
      );
    }
  }
];
