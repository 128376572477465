import { Badge, Typography } from "antd";
import React from "react";
import { StudyPlanTask } from "../../../../../../services/nav-api/studyPlans/model";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const CalendarDayCellTaskSimExam = (props: { task: StudyPlanTask }) => (
  <li>
    <Badge
      color="#9D59DD"
      text={<Typography.Text strong>Simulated Exam {props.task.examIndex! + 1}</Typography.Text>}
    />
  </li>
);
