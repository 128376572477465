import { Flex, Form, Segmented, Typography } from "antd";
import React from "react";
import { StudyPlanEditorContext } from "../../hooks/useStudyPlanEditor";

import _ from "lodash";
import { GiRun } from "react-icons/gi";
import { MdAssistWalker, MdOutlineDirectionsWalk } from "react-icons/md";
import { StudyPace } from "../../hooks/useStudyPlanEditor/useEditorFields/model";

// -------------------------------------------------------------------------------------------------
//  Constants
// -------------------------------------------------------------------------------------------------

const iconByPace: { [pace in StudyPace]: React.ReactNode } = {
  [StudyPace.THOROUGH]: <MdAssistWalker size={28} />,
  [StudyPace.AVERAGE]: <MdOutlineDirectionsWalk size={28} />,
  [StudyPace.QUICK]: <GiRun size={28} />
};

const labelByPace: { [pace in StudyPace]: React.ReactNode } = {
  [StudyPace.THOROUGH]: "Thorough",
  [StudyPace.AVERAGE]: "Average",
  [StudyPace.QUICK]: "Quick"
};

const NoWrapSty: { style: React.CSSProperties } = { style: { textWrap: "wrap" } };
const descriptionByPace: { [pace in StudyPace]: React.ReactNode } = {
  [StudyPace.THOROUGH]: (
    <ul>
      <li {...NoWrapSty}>Use all the available tools</li>
      <li {...NoWrapSty}>Write notes while studying</li>
      <li {...NoWrapSty}>Take more time in videos</li>
    </ul>
  ),
  [StudyPace.AVERAGE]: (
    <ul>
      <li {...NoWrapSty}>Use all available tools</li>
      <li {...NoWrapSty}>Take a few notes</li>
      <li {...NoWrapSty}>See all videos in normal time</li>
    </ul>
  ),
  [StudyPace.QUICK]: (
    <ul>
      <li {...NoWrapSty}>Use only the essential tools</li>
      <li {...NoWrapSty}>Don't take any notes</li>
      <li {...NoWrapSty}>See the videos in 2x speed</li>
    </ul>
  )
};

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const StudyPaceField = () => (
  <StudyPlanEditorContext.Consumer>
    {({ editor: { studyPace }, simulations }) => (
      <Form.Item required label="Choose one of the following options: ">
        <Segmented
          block
          value={studyPace.value}
          onChange={pace => {
            simulations.enableSimulations();
            studyPace.setValue(pace);
          }}
          options={_.chain([StudyPace.THOROUGH, StudyPace.AVERAGE, StudyPace.QUICK])
            .map(value => ({
              value,
              label: (
                <Flex style={{ paddingTop: 5, paddingBottom: 2 }} justify="center" align="center" gap={4} vertical>
                  <Typography.Text>{iconByPace[value]}</Typography.Text>
                  <Typography.Text strong>{labelByPace[value]}</Typography.Text>
                  <Typography.Text className="text-left">{descriptionByPace[value]}</Typography.Text>
                </Flex>
              )
            }))
            .value()}
        />
      </Form.Item>
    )}
  </StudyPlanEditorContext.Consumer>
);
