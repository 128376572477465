import _ from "lodash";
import React from "react";
import { ApplicationToastsContext } from "../../../../components/AppToasts";
import { navApi } from "../../../../services/nav-api";
import { GetSimulatedStudyPlanRSP } from "../../../../services/nav-api/studyPlans/model";
import { StudyPlanEditorFields } from "./useEditorFields";
import { StudyCalendarUtils } from "./utils/StudyCalendarUtils";

// -------------------------------------------------------------------------------------------------
// - Types
// -------------------------------------------------------------------------------------------------

export type StudyPlanEditorSimulationsValue = {
  loading: boolean;
  response: any;
  enableSimulations: () => void;
};

// -------------------------------------------------------------------------------------------------
// - Hooks
// -------------------------------------------------------------------------------------------------

export const useSimulatedData = (editor: StudyPlanEditorFields): StudyPlanEditorSimulationsValue => {
  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState<GetSimulatedStudyPlanRSP | null>(null);
  const [simulationsEnabled, setSimulationsEnabled] = React.useState(false);
  const toastsApi = React.useContext(ApplicationToastsContext);

  // -----------------------------------------------------------------------------------------------
  // -
  // - Callbacks
  // -

  React.useEffect(() => {
    if (
      simulationsEnabled &&
      !_.some(
        [
          editor.section.value,
          editor.startDate.value,
          editor.endDate.value,
          editor.practiceTests.value,
          editor.flashcards.value,
          editor.finalReview.value,
          editor.studyPace.value
        ],
        _.isNil
      )
    ) {
      (async () => {
        try {
          setLoading(true);
          const response = await navApi.studyPlans.fetchSimulatedStudyPlan({
            sectionAltId: editor.section.toApiRequestValue(),
            startDate: editor.startDate.toApiRequestValue(),
            examDate: editor.endDate.toApiRequestValue(),
            pace: editor.studyPace.toApiRequestValue(),
            includePracticeTest: editor.practiceTests.value,
            includeFlashcard: editor.flashcards.value,
            includeFR: editor.finalReview.value,
            weekDayHours: editor.weekDayHours.value,
            daysOff: editor.daysOff.toApiRequestValue(),
            estimatedDate: false,
            plannedExamDate: 3
          });

          if (!_.isNil(response.error)) {
            toastsApi.error({
              message: "Error",
              description: response.error
            });
          } else {
            const val = StudyCalendarUtils.getStudyTimePerWeek(
              editor.startDate.value.toDate(),
              editor.endDate.value?.toDate()!,
              response.plan?.calendar ?? []
            );

            editor.studyTimeWeek.setValue(val);
            editor.weekDayHours.setValue(StudyCalendarUtils.calcWeekDayHours(val!));

            setResponse(response);
          }
        } catch (e) {
          const error: { status: string; message: string } = e;
          toastsApi.error({
            message: "Error",
            description: error.message
          });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [
    editor.section.value,
    editor.startDate.value,
    editor.endDate.value,
    editor.practiceTests.value,
    editor.flashcards.value,
    editor.finalReview.value,
    editor.studyPace.value,
    JSON.stringify(editor.daysOff.value.toString()),
    simulationsEnabled
  ]);

  return {
    loading,
    response,
    enableSimulations: () => {
      setSimulationsEnabled(true);
    }
  };
};

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

export const studyPlanEditorSimulationsInitValue: StudyPlanEditorSimulationsValue = {
  loading: false,
  response: null,
  enableSimulations: _.noop
};
