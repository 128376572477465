import { LoadingOutlined } from "@ant-design/icons";
import { Card, Typography } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationToastsContext } from "../../components/AppToasts";
import { BaseLayout } from "../../components/BaseLayout";
import { NavigationDrawerOptionKey } from "../../components/NavigationDrawer/types";
import { TenantsForm } from "../../components/TenantsForm";
import { routes } from "../../routes";
import { navApi } from "../../services/nav-api";
import { Tenant } from "../../services/nav-api/tenants/types";
import { Status } from "../../services/nav-api/types";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantEdit = () => {
  const navigate = useNavigate();
  const [tenant, setTenant] = React.useState<Tenant>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const tenantId = parseInt(id!);

  React.useEffect(() => {
    navApi.tenants.getTenant(tenantId).then(tenant => setTenant(tenant));
  }, []);

  return (
    <BaseLayout
      activeNavDrawerKey={NavigationDrawerOptionKey.ADMIN_TENANTS}
      breadcrumb={[
        { name: "Tenants", href: routes.tenants.resolve() },
        { name: tenant ? tenant.name : "Tenant", href: routes.tenant.resolve({ id: tenantId.toString() }) },
        { name: "Edit" }
      ]}
    >
      <Typography.Title level={2}>Edit Tenant</Typography.Title>
      <Card>
        {tenant ? (
          <ApplicationToastsContext.Consumer>
            {api => (
              <TenantsForm
                tenantId={tenantId}
                tenant={tenant}
                loading={loading}
                onSubmit={async tp => {
                  try {
                    setLoading(true);
                    await navApi.tenants.editTenant(
                      tenantId,
                      tp.name,
                      tp.status === true ? Status.Enabled : Status.Disabled,
                      tp.products,
                      tp.type!
                    );

                    // Notify Success
                    api.success({
                      message: `Tenant edited with success`,
                      showProgress: true,
                      pauseOnHover: true
                    });

                    // Go back
                    navigate(routes.tenant.resolve({ id: tenantId.toString() }));
                  } catch (e) {
                    // Notify Error
                    api.error({
                      message: `Error editing tenant`,
                      description: e.message,
                      showProgress: true,
                      pauseOnHover: true
                    });
                  } finally {
                    setLoading(false);
                  }
                }}
              />
            )}
          </ApplicationToastsContext.Consumer>
        ) : (
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        )}
      </Card>
    </BaseLayout>
  );
};
