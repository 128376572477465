import { Table, Tag } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { handleCellText, handleDateCell } from "../../components/TableCellRender";
import { doChangeListSettings } from "../../redux/actions/tenants/tenants";
import { RdxFetchStatus } from "../../redux/types/status";
import { routes } from "../../routes";
import { Tenant } from "../../services/nav-api/tenants/types";
import { Status } from "../../services/nav-api/types";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const TenantsTable = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const tenants = useSelector(({ tenants: { tenants } }) => tenants);

  return (
    <Table
      size="small"
      className="default-table"
      rowClassName="cursor-pointer"
      loading={_.isEqual(RdxFetchStatus.LOADING, tenants?.status)}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          render: handleCellText,
          width: "35%"
        },
        {
          title: "Type",
          dataIndex: "type",
          render: handleCellText,
          width: "30%"
        },

        {
          title: "Products",
          dataIndex: "products",
          render: products => handleCellText(products.join(", ").toUpperCase()),
          width: "10%"
        },
        {
          title: "Creation date",
          dataIndex: "created",
          render: handleDateCell,
          width: "15%"
        },
        {
          title: "Status",
          dataIndex: "status",
          render: function RoleCol(status: Status) {
            return <Tag color={status === Status.Disabled ? "red" : "green"}>{status}</Tag>;
          },
          width: "10%"
        }
      ]}
      dataSource={_.map(tenants ? tenants.tenants : [], (r, i) => ({ ...r, key: i }))}
      pagination={{
        current: tenants?.paging?.page ?? 0,
        pageSize: tenants?.paging?.itemsPerPage ?? 0,
        total: tenants?.paging?.totalItems ?? 0,
        pageSizeOptions: ["50", "100", "250"],
        showSizeChanger: true,
        showQuickJumper: true,
        showLessItems: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
      }}
      onChange={({ current: page, pageSize }: PaginationProps) => {
        dispatch(doChangeListSettings(page!, pageSize!));
      }}
      onRow={(record: Tenant) => ({
        onClick: () => {
          navigate(routes.tenant.resolve({ id: record.id.toString() }));
        }
      })}
    />
  );
};
