import { Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RenderChildrenIf } from "../../components/ConditionalRenderers";
import { RangeDateFilter } from "../../components/RangeDateFilter";
import { StatusFilter } from "../../components/StatusFilter";
import { doUpdateWorkingFilter } from "../../redux/actions/users-list";
import { getIsGlobalUser } from "../../redux/selectors/permissions/is-global-user";
import { getUsersListWorkingFilter } from "../../redux/selectors/users-list";
import { UsersTableFilterForRoles } from "./UsersTableFilterForRoles";
import { UsersTableFilterForTenants } from "./UsersTableFilterForTenants";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UsersTableFilters = () => {
  const dispatch = useDispatch<any>();
  const workingFilter = useSelector(getUsersListWorkingFilter);
  const globalUser = useSelector(getIsGlobalUser);
  const [form] = Form.useForm();

  return (
    <Form layout="vertical" form={form}>
      <RenderChildrenIf condition={globalUser}>
        <UsersTableFilterForTenants
          value={workingFilter.tenantId}
          onChange={tenantId => {
            dispatch(doUpdateWorkingFilter({ tenantId }));
          }}
        />
      </RenderChildrenIf>
      <UsersTableFilterForRoles
        value={workingFilter.roleId}
        onChange={roleId => {
          dispatch(doUpdateWorkingFilter({ roleId }));
        }}
      />
      <StatusFilter
        value={workingFilter.status}
        onChange={status => {
          dispatch(doUpdateWorkingFilter({ status }));
        }}
      />
      <RangeDateFilter
        label="Creation Date"
        id="creation-date-filter"
        value={workingFilter.creationDate}
        onChange={creationDate => dispatch(doUpdateWorkingFilter({ creationDate }))}
      />
      <RangeDateFilter
        label="Last Login Date"
        id="last-login-date-filter"
        value={workingFilter.lastLoginDate}
        onChange={lastLoginDate => dispatch(doUpdateWorkingFilter({ lastLoginDate }))}
      />
    </Form>
  );
};
