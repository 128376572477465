import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { MESSAGES } from "../../../i18n";
import { routes } from "../../../routes";
import { useEditCreateSpFlag } from "../hooks/useEditCreateSpFlag";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const NewStudyPlanButton = () => {
  const navigate = useNavigate();
  const canCreateSP = useEditCreateSpFlag();
  return canCreateSP ? (
    <Button
      type="primary"
      onClick={() => {
        navigate(routes.studyPlansNew.path);
      }}
    >
      {MESSAGES.CreateStudyPlan}
    </Button>
  ) : null;
};
