import { Checkbox, List, Select } from "antd";
import _ from "lodash";
import VirtualList from "rc-virtual-list";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTenantsDropdown } from "../redux/actions/tenants/tenants-dropdown";
import { getTenantsDropdownDataSource } from "../redux/selectors/tenants-dropdown-data-source";
import { Tenant } from "../services/nav-api/tenants/types";

export const NoTenantFilterAllValue = 0;
export const TenantIdFilterAllValue = -1;

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

export const TenantsSelect = (props: {
  onChange: (value: number) => void;
  value: number | undefined;
  hideExtraValues?: boolean;
  allowClear?: boolean;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  parentNode?: HTMLElement;
}) => {
  const dispatch = useDispatch<any>();
  const tenants = useSelector(getTenantsDropdownDataSource);

  React.useEffect(() => {
    dispatch(fetchTenantsDropdown());
  }, []);

  return (
    <Select
      showSearch
      placeholder="Tenant name"
      data-qa-label="tenant-select"
      optionFilterProp="children"
      disabled={props.disabled}
      className={props.className}
      allowClear={props.allowClear}
      style={props.style}
      value={tenants && tenants.length ? props.value : undefined}
      getPopupContainer={triggerNode => props.parentNode ?? (triggerNode.parentNode as HTMLElement)}
      filterOption={(input, o) => o!.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onChange={props.onChange}
      options={_.chain(tenants)
        .filter(tenant => !props.hideExtraValues || tenant.id > 0)
        .map(t => ({
          label: t.name,
          value: t.id
        }))
        .value()}
    />
  );
};

// -------------------------------------------------------------------------------------------------
//  Component
// -------------------------------------------------------------------------------------------------

type TenantsListProps = {
  value?: number;
  onChange: (value: number) => void;
  widht?: number;
  itemHeight?: number;
  containerHeight?: number;
};

export const TenantsList = (props: TenantsListProps) => {
  const dispatch = useDispatch<any>();
  const tenants = useSelector(getTenantsDropdownDataSource);

  React.useEffect(() => {
    dispatch(fetchTenantsDropdown());
  }, []);

  return (
    <List size="small" style={{ width: props.widht ?? 300 }} loading={_.isEmpty(tenants)}>
      <VirtualList
        itemKey="id"
        data={_.filter(tenants, ({ id }) => _.gt(id, 0))}
        height={props.containerHeight ?? 300}
        itemHeight={props.itemHeight ?? 20}
      >
        {(item: Pick<Tenant, "id" | "name">) => (
          <List.Item>
            <Checkbox
              checked={_.isEqual(props.value, item.id)}
              onChange={() => {
                props.onChange(item.id);
              }}
            >
              {item.name}
            </Checkbox>
          </List.Item>
        )}
      </VirtualList>
    </List>
  );
};
