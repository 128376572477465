import { DownOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import _ from "lodash";
import React from "react";
import { ApplicationToastsContext } from "../../components/AppToasts";
import { HttpClientErrorResponse } from "../../services/http-client/types";
import { navApi } from "../../services/nav-api";
import { Status } from "../../services/nav-api/types";
import { FormControllerContext } from "./hooks/userFormController";

// -------------------------------------------------------------------------------------------------
// - Constants
// -------------------------------------------------------------------------------------------------

const kMenuItemDisableUser = "disable-user";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UserStatus = () => (
  <FormControllerContext.Consumer>
    {({ serverUser }) =>
      !_.isNil(serverUser.value) ? (
        <ApplicationToastsContext.Consumer>
          {api => (
            <Dropdown
              menu={{
                // -------------------------------------------------------------------------------------
                // - Menu Items
                // -------------------------------------------------------------------------------------

                items: [
                  {
                    key: kMenuItemDisableUser,
                    label: _.isEqual(serverUser.value?.status, Status.Enabled) //
                      ? Status.Disabled
                      : Status.Enabled
                  }
                ],

                // -------------------------------------------------------------------------------------
                // - Event handler
                // -------------------------------------------------------------------------------------

                onClick: async e => {
                  if (_.isEqual(kMenuItemDisableUser, e.key)) {
                    try {
                      if (_.isEqual(serverUser.value?.status, Status.Disabled)) {
                        await navApi.users.enableUser(serverUser.value?.id!);
                      } else {
                        await navApi.users.disableUser(serverUser.value?.id!);
                      }
                    } catch (e) {
                      api.error({
                        message: "Error editing user status",
                        description: (e as HttpClientErrorResponse)?.message
                      });
                    } finally {
                      window.location.reload();
                    }
                  }
                }
              }}
            >
              <Button icon={<SettingOutlined />}>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </ApplicationToastsContext.Consumer>
      ) : null
    }
  </FormControllerContext.Consumer>
);
